import { Pipe, PipeTransform } from '@angular/core';
import { EnumeratedState } from '../helpers/state-helper.service';

@Pipe({
    name: 'stateClass'
})
export class StateClassPipe implements PipeTransform {
    transform(value: string | EnumeratedState | undefined | number | null, args: 'txt' | 'bg' = 'txt'): string {
        if (value == null) {
            return 'state-no-data-' + args;
        }
        if (typeof(value) == 'number') {
            return 'state-' + (value > 75 ? 'ok' : value > 50 ? 'to-be-monitored' : 'failure') + '-' + args;
        } else {
            const enumeratedStates: EnumeratedState[] = ['Ok', 'To Be Monitored', 'Potential Failure', 'Failure', 'No Data'];
            return 'state-' + (enumeratedStates.includes(value as EnumeratedState) ? value!.toLowerCase().replace(/ /g, '-') : 'no-data') + '-' + args;
        }
    }
}
