import { Directive, Input, HostListener } from '@angular/core';
import { DataLayerService } from '../app-services/data-layer.service';

@Directive({
    selector: '[evcTrackClick]'
})
export class TrackClickDirective {
    // label of action / button to be tracked (e.g. "dashboard-search")
    @Input() evcTrackClick: string | undefined;
    // action of click (e.g. "open" / "close")
    @Input() evcTrackClickValue: string | undefined;

    constructor(
        private _dataLayerService: DataLayerService
    ) { }

    @HostListener('click', ["$event"])
    trackClick(event: MouseEvent): void {
        if (!this.evcTrackClick || !this.evcTrackClickValue) return;
        // add click to dataLayer
        this._dataLayerService.logClick(this.evcTrackClick, this.evcTrackClickValue);
    }
}
