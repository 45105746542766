import { CUSTOM_ELEMENTS_SCHEMA, Inject, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { CollapsibleComponent } from './collapsible/collapsible.component';
import { NavigationComponent } from './navigation/navigation.component';
import { RouterModule } from '@angular/router';

import { PlotlyViaWindowModule } from 'angular-plotly.js';

import { ExportComponent } from './export/export.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownButtonComponent } from './dropdown-button/dropdown-button.component';
import { SelectMultipleComponent } from './quick-filters/select-multiple/select-multiple.component';
import { SelectSingleRadioComponent } from './quick-filters/select-single-radio/select-single-radio.component';
import { SelectRangeComponent } from './quick-filters/select-range/select-range.component';
import { SelectDateRangeComponent } from './quick-filters/select-date-range/select-date-range.component';
import { ResizableComponent } from './resizable/resizable.component';
import { SelectSingleComponent } from './quick-filters/select-single/select-single.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { ModalComponent } from './modal/modal.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { CopyAttributeComponent } from './copy-attribute/copy-attribute.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AdminSelectionComponent } from './admin-selection/admin-selection.component';
import { StationsMapComponent } from './stations-map/stations-map.component';
import { RadioButtonGroupComponent } from './radio-button-group/radio-button-group.component';
import { StationFiltersComponent } from "./station-filters/station-filters.component";
import { SelectTableColumnsComponent } from './station-filters/select-table-columns/select-table-columns.component';
import { SelectFiltersComponent } from './station-filters/select-filters/select-filters.component';
import { FiltersetModalComponent } from './station-filters/filterset-modal/filterset-modal.component';
import { ListFiltersComponent } from './station-filters/list-filters/list-filters.component';
import { NavigationMenuComponent } from './navigation/navigation-menu/navigation-menu.component';
import { TimelineHelperService } from '../core/plots/timeline-helper.service';
import { detailsRepository } from '../core/stores/details.repository';
import { NotificationService } from '../core/app-services';
import { CoreModule } from '../core/core.module';
import { AnalyticsPopupComponent } from './stations-map/analytics-popup/analytics-popup.component';
import { StateClassPipe } from '../core/pipes/state-class.pipe';
import { FilterSelectComponent } from './station-filters/filter-select/filter-select.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { TableHeaderComponent } from './table-header/table-header.component';
import { TableComponent } from './table/table.component';
import { FiltersetMaskComponent } from './station-filters/filterset-mask/filterset-mask.component';
import { PlotRangeSliderComponent } from './chart-components/plot-range-slider/plot-range-slider.component';
import { PlotEventSelectionComponent } from './chart-components/plot-event-selection/plot-event-selection.component';
import { StationPlotComponent } from './chart-components/station-plot/station-plot.component';
import { RestartPopupComponent } from './restart-popup/restart-popup.component';
import { ErrorOverlayComponent } from './chart-components/error-overlay/error-overlay.component';
import { TranslateModule } from '@ngx-translate/core';
import { TableBulkPanelComponent } from './table-bulk-panel/table-bulk-panel.component';
import { DateInputComponent } from './date-input/date-input.component';
import { SupportOverlayComponent } from './support-overlay/support-overlay.component';
import { OverallStateFilterComponent } from './station-filters/overall-state-filter/overall-state-filter.component';

@NgModule({
  declarations: [
    NavigationComponent,
    UserSettingsComponent,
    CollapsibleComponent,
    DropdownButtonComponent,
    ExportComponent,
    RestartPopupComponent,
    SelectMultipleComponent,
    SelectSingleRadioComponent,
    SelectRangeComponent,
    SelectDateRangeComponent,
    ResizableComponent,
    CopyAttributeComponent,
    SelectSingleComponent,
    ModalComponent,
    DateRangeComponent,
    StationsMapComponent,
    AdminSelectionComponent,
    RadioButtonGroupComponent,
    StationFiltersComponent,
    SelectTableColumnsComponent,
    SelectFiltersComponent,
    FiltersetModalComponent,
    ListFiltersComponent,
    NavigationMenuComponent,
    AnalyticsPopupComponent,
    StateClassPipe,
    PreloaderComponent,
    FilterSelectComponent,
    TableHeaderComponent,
    TableComponent,
    FiltersetMaskComponent,
    PlotRangeSliderComponent,
    PlotEventSelectionComponent,
    StationPlotComponent,
    ErrorOverlayComponent,
    TableBulkPanelComponent,
    DateInputComponent,
    SupportOverlayComponent,
    OverallStateFilterComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    ScrollingModule,
    PlotlyViaWindowModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzSliderModule,
    DragDropModule,
    TranslateModule
  ],
  exports: [
    NavigationComponent,
    UserSettingsComponent,
    CollapsibleComponent,
    DropdownButtonComponent,
    PlotlyViaWindowModule,
    FormsModule,
    ScrollingModule,
    ExportComponent,
    RestartPopupComponent,
    SelectMultipleComponent,
    SelectSingleRadioComponent,
    SelectRangeComponent,
    SelectDateRangeComponent,
    ResizableComponent,
    SelectSingleComponent,
    ModalComponent,
    DateRangeComponent,
    CopyAttributeComponent,
    DragDropModule,
    StationsMapComponent,
    RadioButtonGroupComponent,
    StationFiltersComponent,
    SelectTableColumnsComponent,
    SelectFiltersComponent,
    FiltersetModalComponent,
    ListFiltersComponent,
    AnalyticsPopupComponent,
    StateClassPipe,
    PreloaderComponent,
    FilterSelectComponent,
    TableHeaderComponent,
    TableComponent,
    FiltersetMaskComponent,
    PlotRangeSliderComponent,
    PlotEventSelectionComponent,
    StationPlotComponent,
    ErrorOverlayComponent,
    TableBulkPanelComponent,
    DateInputComponent,
    SupportOverlayComponent,
    OverallStateFilterComponent,
    TranslateModule
  ],
  providers: [
    {
      provide: TimelineHelperService,
      useFactory: () => new TimelineHelperService(
        Inject(detailsRepository),
        Inject(NotificationService)
      )
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
