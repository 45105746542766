import { Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
    selector: '[contextMenu]'
})
export class ContextMenuDirective implements OnInit, OnDestroy {
    // pass an array of options to display in menu
    @Input() contextMenu: string[] | undefined;
    // optionally pass ref of scrollable parent to handle scrolling events
    @Input() contextScrollableParent?: HTMLElement;
    // toggle between left or right click to activate
    @Input() triggerOn: 'click' | 'contextmenu' | undefined;
    @Output() onContextOptionSelect = new EventEmitter<string>();
    private _currentMenu!: any;
    private _clickedInside: boolean = false;
    private _timer: any;

    private _unlistenEvents: (() => void)[] = [];

    constructor(
        private _renderer: Renderer2,
        private _ngZone: NgZone,
        private readonly _elementRef: ElementRef<HTMLElement>
    ) { }

    ngOnInit(): void {
        this._renderer.listen(this._elementRef.nativeElement, this.triggerOn ?? 'contextmenu', (event: PointerEvent) => this.onContextMenu(event))   
    }

    private _constructMenu(event: PointerEvent): HTMLElement {
        let menu = this._renderer.createElement('ul');
        menu.classList.add('context-menu')
        menu.style.top = event.clientY + 'px';
        menu.style.left = event.clientX + 'px';

        this.contextMenu!.forEach((option) => {
            let optionEl = this._renderer.createElement('li');
            optionEl.innerText = option;
            optionEl.addEventListener('click', () => {
                this.onContextOptionSelect.emit(option)
                this._removeMenu()
            })
            menu.appendChild(optionEl)
        })

        // reset timer while hovering
        menu.addEventListener('mouseenter', () => {
            clearTimeout(this._timer)
        })

        // set new timer to close after hovering
        menu.addEventListener('mouseleave', () => {
            clearTimeout(this._timer)
            this._timer = setTimeout(() => {
                this._removeMenu()
            }, 1000);
        })

        return menu
    }

    private _setListeners() {
        this._ngZone.runOutsideAngular(() => {
            this._unlistenEvents.push(
                // remove menu on scroll and click
                this._renderer.listen('window', 'scroll', () => this._removeMenu()),
                this._renderer.listen('window', 'contextmenu', () => this._handleOtherMenus())
            )

            if (this.triggerOn !== 'click') this._unlistenEvents.push(
                this._renderer.listen('window', 'click', () => this._removeMenu())
            )
            
            // listen to scroll of optional parent, remove contextmenu
            if (this.contextScrollableParent !== undefined) {
                this._unlistenEvents.push(
                    this._renderer.listen(this.contextScrollableParent, 'scroll', () => this._removeMenu())
                )
            }
        })
    }

    private _removeMenu() {
        if (this._currentMenu) {
            this._currentMenu.remove()
            this._unlistenEvents.forEach((fn) => fn())
        }
    }

    private _handleOtherMenus() {
        if (!this._clickedInside) {
            this._removeMenu()
        }
        this._clickedInside = false
    }

    onContextMenu(event: PointerEvent) {
        if (!this.contextMenu) return
        this._clickedInside = true;
        event.preventDefault();
        this._removeMenu();
        const menu = this._constructMenu(event)
        this._renderer.appendChild(document.body, menu)
        this._setListeners()
        this._currentMenu = menu

        this._timer = setTimeout(() => {
            this._removeMenu()
        }, 4000);
    }


    ngOnDestroy(): void {
        this._removeMenu()
    }
}
