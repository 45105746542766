import { inject, Pipe, PipeTransform } from '@angular/core';
import { ChargingStation, Connector } from '../data-backend/models';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'chargerKeyName'
})
export class ChargerKeyName implements PipeTransform {

    constructor(
        private _translate: TranslateService
    ) {}

    transform(key: keyof ChargingStation | keyof Connector | string, args?: any): any {
        const translateMapping = `VAR_NAMES.${key}`;
        const frontendName = this._translate.instant(translateMapping);
        // provide translated varName, if not found destructure camelCased key
        return frontendName !== translateMapping ? frontendName : key[0].toUpperCase() + key.substring(1).replace(/(?=[A-Z][a-z])/g, ' ');
    }
}
