/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApiResponse } from '../models/api-response';
import { ChargedAmount } from '../models/charged-amount';
import { ChargersInFailure } from '../models/chargers-in-failure';
import { ChargingStation } from '../models/charging-station';
import { EnergyMeterValue } from '../models/energy-meter-value';
import { NumSessions } from '../models/num-sessions';
import { OverviewMetaInfo } from '../models/overview-meta-info';
import { ResetResponse } from '../models/reset-response';
import { StationLocations } from '../models/station-locations';


/**
 * Charging Stations Overview Table
 */
@Injectable({
  providedIn: 'root',
})
export class OverviewService extends DataBaseService {
  constructor(
    config: DataApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getChargingStations
   */
  static readonly GetChargingStationsPath = '/chargingStations/';

  /**
   * Gets list of charging stations.
   *
   * Fetch a paginated list of charging stations, optionally for a specific date
   * The returned list of charging stations is basically paginated. Only if latMin, latMax, lonMin and lonMax are set, the complete result list is returned. In that case the pagination and sorting parameters are ignored. In addition, the charging stations contain only the attributes needed for the display in the map.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChargingStations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingStations$Response(params?: {

    /**
     * Time at which the list of chargers should be displayed
     */
    date?: string;

    /**
     * Number of the page
     */
    pageNumber?: number;

    /**
     * Number of items per page
     */
    perPage?: number;

    /**
     * Attribute to sort by
     */
    sortBy?: string;

    /**
     * Order of sorting
     */
    sortOrder?: 'asc' | 'desc';

    /**
     * Term to search for in all fields of &quot;#/components/schemas/ChargingStation&quot;
     */
    search?: string;

    /**
     * String representation of a complex search expression in url-encoded format. The query is a list of key-value pairs, the key being a field of &quot;#/components/schemas/ChargingStation&quot;
     */
    searchFields?: string;

    /**
     * String representation of a complex filter expression
     */
    filter?: string;

    /**
     * Minimum latitude of map section
     */
    latMin?: number;

    /**
     * Minimum latitude of map section
     */
    latMax?: number;

    /**
     * Maximum longitude of map section
     */
    lonMin?: number;

    /**
     * Maximum longitude of map section
     */
    lonMax?: number;

    /**
     * Include only hidden stations
     */
    showOnlyHidden?: boolean;
  }): Observable<StrictHttpResponse<{
'stations'?: Array<ChargingStation>;
'meta'?: OverviewMetaInfo;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OverviewService.GetChargingStationsPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
      rb.query('pageNumber', params.pageNumber, {});
      rb.query('perPage', params.perPage, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('search', params.search, {});
      rb.query('searchFields', params.searchFields, {});
      rb.query('filter', params.filter, {});
      rb.query('latMin', params.latMin, {});
      rb.query('latMax', params.latMax, {});
      rb.query('lonMin', params.lonMin, {});
      rb.query('lonMax', params.lonMax, {});
      rb.query('showOnlyHidden', params.showOnlyHidden, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'stations'?: Array<ChargingStation>;
        'meta'?: OverviewMetaInfo;
        }>;
      })
    );
  }

  /**
   * Gets list of charging stations.
   *
   * Fetch a paginated list of charging stations, optionally for a specific date
   * The returned list of charging stations is basically paginated. Only if latMin, latMax, lonMin and lonMax are set, the complete result list is returned. In that case the pagination and sorting parameters are ignored. In addition, the charging stations contain only the attributes needed for the display in the map.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getChargingStations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingStations(params?: {

    /**
     * Time at which the list of chargers should be displayed
     */
    date?: string;

    /**
     * Number of the page
     */
    pageNumber?: number;

    /**
     * Number of items per page
     */
    perPage?: number;

    /**
     * Attribute to sort by
     */
    sortBy?: string;

    /**
     * Order of sorting
     */
    sortOrder?: 'asc' | 'desc';

    /**
     * Term to search for in all fields of &quot;#/components/schemas/ChargingStation&quot;
     */
    search?: string;

    /**
     * String representation of a complex search expression in url-encoded format. The query is a list of key-value pairs, the key being a field of &quot;#/components/schemas/ChargingStation&quot;
     */
    searchFields?: string;

    /**
     * String representation of a complex filter expression
     */
    filter?: string;

    /**
     * Minimum latitude of map section
     */
    latMin?: number;

    /**
     * Minimum latitude of map section
     */
    latMax?: number;

    /**
     * Maximum longitude of map section
     */
    lonMin?: number;

    /**
     * Maximum longitude of map section
     */
    lonMax?: number;

    /**
     * Include only hidden stations
     */
    showOnlyHidden?: boolean;
  }): Observable<{
'stations'?: Array<ChargingStation>;
'meta'?: OverviewMetaInfo;
}> {

    return this.getChargingStations$Response(params).pipe(
      map((r: StrictHttpResponse<{
'stations'?: Array<ChargingStation>;
'meta'?: OverviewMetaInfo;
}>) => r.body as {
'stations'?: Array<ChargingStation>;
'meta'?: OverviewMetaInfo;
})
    );
  }

  /**
   * Path part for operation resetChargingStations
   */
  static readonly ResetChargingStationsPath = '/chargingStations/reset/';

  /**
   * Resets a list of charging stations.
   *
   * Send a OCPP reset command to a list of charging stations
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetChargingStations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetChargingStations$Response(params: {
    body: {

/**
 * Unique identifiers of the Charging Stations
 */
'stationIds'?: Array<string>;
}
  }): Observable<StrictHttpResponse<ResetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OverviewService.ResetChargingStationsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResetResponse>;
      })
    );
  }

  /**
   * Resets a list of charging stations.
   *
   * Send a OCPP reset command to a list of charging stations
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resetChargingStations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetChargingStations(params: {
    body: {

/**
 * Unique identifiers of the Charging Stations
 */
'stationIds'?: Array<string>;
}
  }): Observable<ResetResponse> {

    return this.resetChargingStations$Response(params).pipe(
      map((r: StrictHttpResponse<ResetResponse>) => r.body as ResetResponse)
    );
  }

  /**
   * Path part for operation changeVisibilityOfChargingStations
   */
  static readonly ChangeVisibilityOfChargingStationsPath = '/chargingStations/changeVisibility/';

  /**
   * Changes the visibility of a list of charging stations.
   *
   * The list of charging stations will be shown or hidden for the current user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeVisibilityOfChargingStations()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeVisibilityOfChargingStations$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationIds: Array<string>;

    /**
     * New visibility status of the specific stations
     */
    visibility?: 'show' | 'hide';

    /**
     * For how many days the stations should be hidden, default 14
     */
    interval?: number;
  }): Observable<StrictHttpResponse<ApiResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OverviewService.ChangeVisibilityOfChargingStationsPath, 'patch');
    if (params) {
      rb.query('stationIds', params.stationIds, {});
      rb.query('visibility', params.visibility, {});
      rb.query('interval', params.interval, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponse>;
      })
    );
  }

  /**
   * Changes the visibility of a list of charging stations.
   *
   * The list of charging stations will be shown or hidden for the current user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeVisibilityOfChargingStations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeVisibilityOfChargingStations(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationIds: Array<string>;

    /**
     * New visibility status of the specific stations
     */
    visibility?: 'show' | 'hide';

    /**
     * For how many days the stations should be hidden, default 14
     */
    interval?: number;
  }): Observable<ApiResponse> {

    return this.changeVisibilityOfChargingStations$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponse>) => r.body as ApiResponse)
    );
  }

  /**
   * Path part for operation getChartChargedAmount
   */
  static readonly GetChartChargedAmountPath = '/chargedAmount/';

  /**
   * Gets data for chart 'Charged Amount'.
   *
   * Fetch all necessary data for a chart showing the summed charge amount of all displayed chargers for a given time interval divided into segments of the same length.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChartChargedAmount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChartChargedAmount$Response(params?: {

    /**
     * End of time interval
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27;
     */
    interval?: number;

    /**
     * The length of all segments in minutes
     */
    segmentLength?: number;

    /**
     * Unique identifier of the Charging Station
     */
    stationIds?: Array<string>;

    /**
     * Term to search for in all fields of &quot;#/components/schemas/ChargingStation&quot;
     */
    search?: string;

    /**
     * String representation of a complex search expression in url-encoded format. The query is a list of key-value pairs, the key being a field of &quot;#/components/schemas/ChargingStation&quot;
     */
    searchFields?: string;

    /**
     * String representation of a complex filter expression
     */
    filters?: string;

    /**
     * Include only hidden stations
     */
    showOnlyHidden?: boolean;
  }): Observable<StrictHttpResponse<Array<ChargedAmount>>> {

    const rb = new RequestBuilder(this.rootUrl, OverviewService.GetChartChargedAmountPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
      rb.query('interval', params.interval, {});
      rb.query('segmentLength', params.segmentLength, {});
      rb.query('stationIds', params.stationIds, {});
      rb.query('search', params.search, {});
      rb.query('searchFields', params.searchFields, {});
      rb.query('filters', params.filters, {});
      rb.query('showOnlyHidden', params.showOnlyHidden, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ChargedAmount>>;
      })
    );
  }

  /**
   * Gets data for chart 'Charged Amount'.
   *
   * Fetch all necessary data for a chart showing the summed charge amount of all displayed chargers for a given time interval divided into segments of the same length.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getChartChargedAmount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChartChargedAmount(params?: {

    /**
     * End of time interval
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27;
     */
    interval?: number;

    /**
     * The length of all segments in minutes
     */
    segmentLength?: number;

    /**
     * Unique identifier of the Charging Station
     */
    stationIds?: Array<string>;

    /**
     * Term to search for in all fields of &quot;#/components/schemas/ChargingStation&quot;
     */
    search?: string;

    /**
     * String representation of a complex search expression in url-encoded format. The query is a list of key-value pairs, the key being a field of &quot;#/components/schemas/ChargingStation&quot;
     */
    searchFields?: string;

    /**
     * String representation of a complex filter expression
     */
    filters?: string;

    /**
     * Include only hidden stations
     */
    showOnlyHidden?: boolean;
  }): Observable<Array<ChargedAmount>> {

    return this.getChartChargedAmount$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ChargedAmount>>) => r.body as Array<ChargedAmount>)
    );
  }

  /**
   * Path part for operation getChartChargersInFailure
   */
  static readonly GetChartChargersInFailurePath = '/chargersInFailure/';

  /**
   * Gets data for chart 'Chargers in Failure'.
   *
   * Fetch all necessary data for a chart showing the amount of chargers with a status "Failure" for a given time interval divided into days.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChartChargersInFailure()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChartChargersInFailure$Response(params?: {

    /**
     * End of time interval
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27;
     */
    interval?: number;

    /**
     * Unique identifier of the Charging Stations
     */
    stationIds?: Array<string>;

    /**
     * Term to search for in all fields of &quot;#/components/schemas/ChargingStation&quot;
     */
    search?: string;

    /**
     * String representation of a complex search expression in url-encoded format. The query is a list of key-value pairs, the key being a field of &quot;#/components/schemas/ChargingStation&quot;
     */
    searchFields?: string;

    /**
     * String representation of a complex filter expression
     */
    filters?: string;

    /**
     * Include only hidden stations
     */
    showOnlyHidden?: boolean;
  }): Observable<StrictHttpResponse<Array<ChargersInFailure>>> {

    const rb = new RequestBuilder(this.rootUrl, OverviewService.GetChartChargersInFailurePath, 'get');
    if (params) {
      rb.query('date', params.date, {});
      rb.query('interval', params.interval, {});
      rb.query('stationIds', params.stationIds, {});
      rb.query('search', params.search, {});
      rb.query('searchFields', params.searchFields, {});
      rb.query('filters', params.filters, {});
      rb.query('showOnlyHidden', params.showOnlyHidden, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ChargersInFailure>>;
      })
    );
  }

  /**
   * Gets data for chart 'Chargers in Failure'.
   *
   * Fetch all necessary data for a chart showing the amount of chargers with a status "Failure" for a given time interval divided into days.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getChartChargersInFailure$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChartChargersInFailure(params?: {

    /**
     * End of time interval
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27;
     */
    interval?: number;

    /**
     * Unique identifier of the Charging Stations
     */
    stationIds?: Array<string>;

    /**
     * Term to search for in all fields of &quot;#/components/schemas/ChargingStation&quot;
     */
    search?: string;

    /**
     * String representation of a complex search expression in url-encoded format. The query is a list of key-value pairs, the key being a field of &quot;#/components/schemas/ChargingStation&quot;
     */
    searchFields?: string;

    /**
     * String representation of a complex filter expression
     */
    filters?: string;

    /**
     * Include only hidden stations
     */
    showOnlyHidden?: boolean;
  }): Observable<Array<ChargersInFailure>> {

    return this.getChartChargersInFailure$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ChargersInFailure>>) => r.body as Array<ChargersInFailure>)
    );
  }

  /**
   * Path part for operation getMeterValue
   */
  static readonly GetMeterValuePath = '/chargingStations/{stationId}/meterValuesEstimation/';

  /**
   * Gets energy meter values for a single charging station for the meter value estimator.
   *
   * Fetch the last energy meter values before the selected date for each connector of a single charging station. If no date is selected, it fetches the last value received. The values are used in the meter value estimator feature.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMeterValue()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMeterValue$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * Datetime up to which the last meter value should be returned
     */
    date?: string;
  }): Observable<StrictHttpResponse<Array<EnergyMeterValue>>> {

    const rb = new RequestBuilder(this.rootUrl, OverviewService.GetMeterValuePath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EnergyMeterValue>>;
      })
    );
  }

  /**
   * Gets energy meter values for a single charging station for the meter value estimator.
   *
   * Fetch the last energy meter values before the selected date for each connector of a single charging station. If no date is selected, it fetches the last value received. The values are used in the meter value estimator feature.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMeterValue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMeterValue(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * Datetime up to which the last meter value should be returned
     */
    date?: string;
  }): Observable<Array<EnergyMeterValue>> {

    return this.getMeterValue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EnergyMeterValue>>) => r.body as Array<EnergyMeterValue>)
    );
  }

  /**
   * Path part for operation getLocationsOfChargingStations
   */
  static readonly GetLocationsOfChargingStationsPath = '/stationLocations/';

  /**
   * Gets map information of all charging stations.
   *
   * Gets location and ID information from all filtered charging stations, optionally for a specific time interval
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocationsOfChargingStations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationsOfChargingStations$Response(params?: {

    /**
     * Time at which the list of chargers should be displayed
     */
    date?: string;

    /**
     * Term to search for in all fields of &quot;#/components/schemas/ChargingStation&quot;
     */
    search?: string;

    /**
     * String representation of a complex search expression in url-encoded format. The query is a list of key-value pairs, the key being a field of &quot;#/components/schemas/ChargingStation&quot;
     */
    searchFields?: string;

    /**
     * String representation of a complex filter expression
     */
    filters?: string;

    /**
     * Include only hidden stations
     */
    showOnlyHidden?: boolean;
  }): Observable<StrictHttpResponse<Array<StationLocations>>> {

    const rb = new RequestBuilder(this.rootUrl, OverviewService.GetLocationsOfChargingStationsPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
      rb.query('search', params.search, {});
      rb.query('searchFields', params.searchFields, {});
      rb.query('filters', params.filters, {});
      rb.query('showOnlyHidden', params.showOnlyHidden, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StationLocations>>;
      })
    );
  }

  /**
   * Gets map information of all charging stations.
   *
   * Gets location and ID information from all filtered charging stations, optionally for a specific time interval
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLocationsOfChargingStations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationsOfChargingStations(params?: {

    /**
     * Time at which the list of chargers should be displayed
     */
    date?: string;

    /**
     * Term to search for in all fields of &quot;#/components/schemas/ChargingStation&quot;
     */
    search?: string;

    /**
     * String representation of a complex search expression in url-encoded format. The query is a list of key-value pairs, the key being a field of &quot;#/components/schemas/ChargingStation&quot;
     */
    searchFields?: string;

    /**
     * String representation of a complex filter expression
     */
    filters?: string;

    /**
     * Include only hidden stations
     */
    showOnlyHidden?: boolean;
  }): Observable<Array<StationLocations>> {

    return this.getLocationsOfChargingStations$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StationLocations>>) => r.body as Array<StationLocations>)
    );
  }

  /**
   * Path part for operation getNumSessions
   */
  static readonly GetNumSessionsPath = '/numSessions/';

  /**
   * Gets number of charging sessions in timeframe aggregated in timesteps.
   *
   * Gets number of charging sessions for a selected timeframe, aggregated in timesteps and zero, short, regular charging sessions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNumSessions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNumSessions$Response(params?: {

    /**
     * Date until which the sessions should be considered, else current date
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all overall states should be returned
     */
    interval?: number;

    /**
     * Term to search for in all fields of &quot;#/components/schemas/ChargingStation&quot;
     */
    search?: string;

    /**
     * String representation of a complex search expression in url-encoded format. The query is a list of key-value pairs, the key being a field of &quot;#/components/schemas/ChargingStation&quot;
     */
    searchFields?: string;

    /**
     * String representation of a complex filter expression
     */
    filters?: string;

    /**
     * Include only hidden stations
     */
    showOnlyHidden?: boolean;
  }): Observable<StrictHttpResponse<Array<NumSessions>>> {

    const rb = new RequestBuilder(this.rootUrl, OverviewService.GetNumSessionsPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
      rb.query('interval', params.interval, {});
      rb.query('search', params.search, {});
      rb.query('searchFields', params.searchFields, {});
      rb.query('filters', params.filters, {});
      rb.query('showOnlyHidden', params.showOnlyHidden, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NumSessions>>;
      })
    );
  }

  /**
   * Gets number of charging sessions in timeframe aggregated in timesteps.
   *
   * Gets number of charging sessions for a selected timeframe, aggregated in timesteps and zero, short, regular charging sessions
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNumSessions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNumSessions(params?: {

    /**
     * Date until which the sessions should be considered, else current date
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all overall states should be returned
     */
    interval?: number;

    /**
     * Term to search for in all fields of &quot;#/components/schemas/ChargingStation&quot;
     */
    search?: string;

    /**
     * String representation of a complex search expression in url-encoded format. The query is a list of key-value pairs, the key being a field of &quot;#/components/schemas/ChargingStation&quot;
     */
    searchFields?: string;

    /**
     * String representation of a complex filter expression
     */
    filters?: string;

    /**
     * Include only hidden stations
     */
    showOnlyHidden?: boolean;
  }): Observable<Array<NumSessions>> {

    return this.getNumSessions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NumSessions>>) => r.body as Array<NumSessions>)
    );
  }

}
