/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RestartEvent } from '../models/restart-event';


/**
 * Analysis Tab for the restart History of a Charging Station
 */
@Injectable({
  providedIn: 'root',
})
export class DetailsRestartService extends DataBaseService {
  constructor(
    config: DataApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getRestartEventsOfChargingStation
   */
  static readonly GetRestartEventsOfChargingStationPath = '/chargingStations/{stationId}/restartEvents/';

  /**
   * Gets restart events of charging station.
   *
   * Fetch the history of last restarts of a specific charging station, optionally for a specific date interval
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRestartEventsOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRestartEventsOfChargingStation$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * Time at which the restarts information of a specific charging station should be returned
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all restarts information should be returned
     */
    interval?: number;
  }): Observable<StrictHttpResponse<Array<RestartEvent>>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsRestartService.GetRestartEventsOfChargingStationPath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
      rb.query('date', params.date, {});
      rb.query('interval', params.interval, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RestartEvent>>;
      })
    );
  }

  /**
   * Gets restart events of charging station.
   *
   * Fetch the history of last restarts of a specific charging station, optionally for a specific date interval
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRestartEventsOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRestartEventsOfChargingStation(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * Time at which the restarts information of a specific charging station should be returned
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all restarts information should be returned
     */
    interval?: number;
  }): Observable<Array<RestartEvent>> {

    return this.getRestartEventsOfChargingStation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RestartEvent>>) => r.body as Array<RestartEvent>)
    );
  }

}
