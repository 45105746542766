import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'localizedDate',
    pure: false
})
export class LocalizedDatePipe implements PipeTransform {
    constructor(
        private _translate: TranslateService,
        private _datePipe: DatePipe
    ) {}

    transform(value: string | number | Date, pattern: string = 'mediumDate'): string | null {
        return this._datePipe.transform(value, pattern, undefined, this._translate.currentLang);
    }
}
