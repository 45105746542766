import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SmoothHeightDirective } from './directives/smoothHeight.directive';
import { SortDirective } from './directives/sort.directive';
import { CopyToClipboardDirective } from './directives/copyToClipboard.directive';
import { ResizableColumnDirective } from './directives/resizableColumn.directive';
import { IndeterminateDirective } from './directives/indeterminate.directive';
import { ResizedDirective } from './directives/resized.directive';
import { ContextMenuDirective } from './directives/contextMenu.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { ChargerKeyName } from './pipes/charger-key-name.pipe';
import { StateClassPipe } from './pipes/state-class.pipe';
import { HasPermissionsDirective } from './directives/hasPermissions.directive';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedDecimalPipe } from './pipes/localized-decimal.pipe';
import { LocalizedStateNamePipe } from './pipes/localized-state-name.pipe';
import { TrackClickDirective } from './directives/trackClickEvent.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ChargerKeyName,
        TooltipDirective,
        ContextMenuDirective,
        ResizedDirective,
        IndeterminateDirective,
        ResizableColumnDirective,
        CopyToClipboardDirective,
        SortDirective,
        SmoothHeightDirective,
        HasPermissionsDirective,
        LocalizedDatePipe,
        LocalizedDecimalPipe,
        LocalizedStateNamePipe,
        TrackClickDirective
    ],
    exports: [
        ChargerKeyName,
        TooltipDirective,
        ContextMenuDirective,
        ResizedDirective,
        IndeterminateDirective,
        ResizableColumnDirective,
        CopyToClipboardDirective,
        SortDirective,
        SmoothHeightDirective,
        HasPermissionsDirective,
        LocalizedDatePipe,
        LocalizedDecimalPipe,
        LocalizedStateNamePipe,
        TrackClickDirective
    ],
    providers: [
        StateClassPipe,
        ChargerKeyName,
        DatePipe,
        TranslateService
    ]
})
export class CoreModule { }
