import { inject, Pipe, PipeTransform, Signal } from '@angular/core';
import { LocalizedStateNameService } from '../app-services/localized-state-name.service';
import { EnumeratedState } from '../helpers/state-helper.service';
import { Observable, of } from 'rxjs';

@Pipe({
    name: 'localizedStateName',
    pure: true
})
export class LocalizedStateNamePipe implements PipeTransform {
    private readonly _localizedStateNameService = inject(LocalizedStateNameService);

    /**
     * 
     * @param value EnumeratedState | string
     * @returns Observable of translated State
     * @example Use with async pipe: {{ myState | localizedStateName | async }}
     */
    transform(value: EnumeratedState | string | undefined): Observable<string> {
        return value ? this._localizedStateNameService.stateObservable(value) : of('');
    }

}
