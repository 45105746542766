import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class NotificationService {
    constructor(
        private _toastr: ToastrService,
        private _translate: TranslateService
    ) { }

    showSuccess(message: string): void {
        this._toastr.success(message)
    }

    showInfo(message: string, title?: string) {
        this._toastr.info(message, title)
    }

    showWarning(message: string, title?: string) {
        this._toastr.warning(message, title)
    }

    showError(message: string, title?: string): void {
        this._toastr.error(message, title)
    }

    showLocalizedSuccess(message: string): void {
        this._toastr.success(this._translate.instant(message))
    }

    showLocalizedInfo(message: string, title?: string) {
        title = title && this._translate.instant(title);
        this._toastr.info(this._translate.instant(message), title)
    }

    showLocalizedWarning(message: string, title?: string) {
        title = title && this._translate.instant(title);
        this._toastr.warning(this._translate.instant(message), title)
    }

    showLocalizedError(message: string, title?: string): void {
        title = title && this._translate.instant(title);
        this._toastr.error(this._translate.instant(message), title)
    }
}
