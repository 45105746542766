import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedNumber',
  pure: false
})
export class LocalizedDecimalPipe implements PipeTransform {
    constructor(
        private _translate: TranslateService,
        private _decimalPipe: DecimalPipe
    ) {}

    transform(value: string | number, digitsInfo?: string): string | null {
        return this._decimalPipe.transform(value, digitsInfo, this._translate.currentLang)
    }
}
