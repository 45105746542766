import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { trackRequestResult } from "@ngneat/elf-requests";
import { BehaviorSubject, catchError, combineLatest, debounceTime, distinctUntilChanged,EMPTY,filter, forkJoin, from, iif, interval, map, mergeMap, Observable, of, pipe, ReplaySubject, retry, share, startWith, switchMap, take, tap, timer, toArray, withLatestFrom } from "rxjs";
import { ConfigurationService, OverviewService, UserService } from "../data-backend/data-services";
import { overviewRepository } from "../stores/overview.repository";
import { NotificationService } from "./notification.service";
import { appRepository } from "../stores/app.repository";
import { PermissionsService } from "./permissions.service";
import { StateHelperService } from "../helpers/state-helper.service";
import { FiltersetsService } from "../data-backend/services/filtersets.service";
import { FilterOption } from "../data-backend/models";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { stationFiltersRepository } from "../stores/station-filters.repository";
import { HttpErrorResponse } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { filterQueryBuilder } from "../helpers/utils.helper";

const REFETCH_STATIONS_INTERVAL = 900_000;
const REFETCH_MAP_INTERVAL = 900_000;
const REFETCH_FILTERS_INTERVAL = 600_000;

@Injectable({ providedIn: 'root' })
// handles cached fetches for overview data and plots
export class OverviewCacheService {
    // causes refetch of user filterSets
    private _filterSetsRefresh$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    // 3 minute timer to refresh Alerts
    private _refreshAlertCacheTimer$ = timer(0, 180_000);
    // causes manual refetch of user Alerts
    private _alertsRefresh$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    // synced timer
    private _refreshCacheTimer$ = timer(0, 600_000);
    // default sorting key
    private _defaultSortingKey$: Observable<string> = this._permService.userPermissions$.pipe(
        map(() => this._permService.hasStateModel('lastOverallState')
            ? 'lastOverallState'
            : (this._permService.getPermissions('global.featuredStates').filter((x) => x).flatMap((x) => x) as string[])[0]
        )
    )

    constructor(
        private _repo: overviewRepository,
        private _appRepo: appRepository,
        private _overviewService: OverviewService,
        private _configurationService: ConfigurationService,
        private _notificationService: NotificationService,
        private _filtersetsService: FiltersetsService,
        private _userService: UserService,
        private _permService: PermissionsService,
        private _stateHelper: StateHelperService,
        private _router: Router,
        private _filtersRepo: stationFiltersRepository,
        private _translate: TranslateService
    ) {
        // prepare single key / value object from searches by columnIds
        const searchColumn$ = this._repo.searchColumns$.pipe(
            withLatestFrom(this._repo.allColumns$),
            map(([searchColumns, allColumns]) => {
                let columnSearch: Record<string, string> = {};
                searchColumns.forEach((searchColumn) => {
                    const col = allColumns.find((col) => col.id === searchColumn.id);
                    const camelCaseColumnName = col?.name.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
                    const allKeys = ([] as any[]).concat((col?.keyInArray ?? []), (col?.keyInConnector ?? []));
                    // map each key with value of columnSearch in single obj
                    const mapped = allKeys.reduce((acc, key) => {
                        return {...acc, [camelCaseColumnName + '.' + key]: searchColumn.value};
                    }, {});

                    columnSearch = Object.assign(columnSearch, mapped)
                })
                return columnSearch
            })
        )

        // get current url ending
        const navigationEnd$ = this._router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map((event) => (event as NavigationEnd).url),
            startWith(this._router.url)
        );

        // listen to all changes that should trigger a refetch of stations
        const stationsUpdates$ = combineLatest({
            searchQuery:    this._repo.searchQuery$,
            activePageNum:  this._repo.activePageNum$,
            activeFilters:  this._filtersRepo.activeFilterValues$,
            sortBy:         this._repo.sortBy$,
            sortDirection:  this._repo.sortDirection$,
            showOnlyHidden: this._repo.showOnlyHidden$,
            refetchRev:     this._repo.refetchRev$,
            customer:       this._appRepo.selectedCustomer$,
            searchColumn:   searchColumn$,
            defaultSortingKey: this._defaultSortingKey$,
            currView:       navigationEnd$
        }).pipe(
            debounceTime(5),
            filter(({customer, currView}) => 
                customer !== null && customer.identifier !== null && customer.identifier !== '' && currView === '/overview'
            ),
            map((combined) => {
                // strip empty filters from store data
                combined.activeFilters = combined.activeFilters.filter((filter) =>
                    filter.value !== undefined && filter.value !== null && filter.value.length !== 0
                )
                return combined
            }),
            distinctUntilChanged((prev, current) => this._compareChanges(prev, current)),
            share({connector: () => new ReplaySubject(1)})
        );

        // timer resets with new req, then emits every 15 min
        const stationsUpdateTimerResettable$ = stationsUpdates$.pipe(
            switchMap(() => interval(REFETCH_STATIONS_INTERVAL))
        )

        // limits resettableTimer$ to only emit while on map view
        const stationsTimerRefetch$ = combineLatest({
            resTimer: stationsUpdateTimerResettable$,
            currView: navigationEnd$
        }).pipe(
            startWith({resTimer: -1, currView: ''}), // causes fetching at least once
            filter(({resTimer, currView}) => resTimer === -1 || currView === '/overview')
        )

        // store last timer count to compare against
        // if change comes from stationsUpdates$, the timerRef will be the same as from last emission
        // thus we can pass a "silent" flag with the data to not call view update events on map when updating by auto-refresh
        let lastStationTimerRef: number | undefined;
        // station data for Dashboard Table
        combineLatest([
            stationsUpdates$,
            stationsTimerRefetch$
        ]).pipe(
            takeUntilDestroyed(),
            switchMap(([combined, timerRef]) => {
                const { defaultSortingKey } = combined;

                // set stationAutoUpdate flag before starting the request, to better handle loading states
                let isAutoUpdate = lastStationTimerRef !== undefined && timerRef.resTimer !== lastStationTimerRef;
                lastStationTimerRef = timerRef.resTimer;
                this._repo.updateStationAutoUpdateFlag(isAutoUpdate);
                
                let request = this._requestBuilder(combined, defaultSortingKey);
                return this._overviewService.getChargingStations(request).pipe(
                    retry({count: 1, delay: 5000}),
                    // set result in repo
                    tap(({stations, meta}) => {
                        // patch stations lastOverallState
                        if (!this._permService.hasStateModel('lastOverallState')) {
                            const featuredStates = this._permService.getPermissions('global.featuredStates').flatMap((x) => x) as string[];
                            stations = stations?.map((station) => this._stateHelper.patchOverallState(station, featuredStates))
                        }
                        // make sure connector IDs are properly ordered
                        stations = stations?.map((station) => ({
                            ...station, 
                            connectors: station.connectors.sort((a, b) => a.connectorId - b.connectorId)
                        }))

                        this._repo.updateStationCache(stations)
                        this._repo.updateMetaCache(meta)
                    }),
                    // track request for state management in repo
                    trackRequestResult(['stations'], {skipCache: true}),
                    catchError((error: HttpErrorResponse) => {
                        // TODO: define on which error status to reset
                        // start resetting values from overview store
                        this._resetToDefaultState({
                            searchQuery:    combined.searchQuery,
                            activeFilters:  combined.activeFilters,
                            sortBy:         combined.sortBy,
                            searchColumn:  combined.searchColumn
                        }, defaultSortingKey)
                        return of([])
                    })
                )
            })
        ).subscribe()

        // listen for changes to update any plot data
        const plotChanges$ = combineLatest({
            searchQuery:    this._repo.searchQuery$,
            activeFilters:  this._filtersRepo.activeFilterValues$,
            showOnlyHidden: this._repo.showOnlyHidden$,
            refetchRev:     this._repo.refetchRev$,
            customer:       this._appRepo.selectedCustomer$,
            searchColumn:   searchColumn$,
            navigationEnd:  navigationEnd$,
            defaultSortingKey: this._defaultSortingKey$
        }).pipe(
            takeUntilDestroyed(),
            debounceTime(5),
            filter(({customer}) => customer !== null && customer.identifier !== null && customer.identifier !== '' ),
            map((combined) => {
                // only apply searchColumn if the user is able to edit them  (on the dashboard)
                const isOnDashboard = combined.navigationEnd === '/overview';
                combined.searchColumn = isOnDashboard ? combined.searchColumn : {};
                // strip empty filters from store data
                combined.activeFilters = combined.activeFilters.filter((filter) => 
                    filter.value !== undefined && filter.value !== null && filter.value.length !== 0
                )
                return combined
            }),
            distinctUntilChanged((prev, current) => this._compareChanges(prev, current)),
            share({connector: () => new ReplaySubject(1)})
        )

        // OV plot data for numSessions and chargedAmount
        plotChanges$.pipe(
            takeUntilDestroyed(),
            switchMap((combined) => {
                const { defaultSortingKey } = combined;
                let request = this._requestBuilder(combined, defaultSortingKey)
                // req w filter for plots differs from getChargingStations
                request["filters"] = request["filter"];

                return combineLatest({
                    numSessions: this._overviewService.getNumSessions(request).pipe(
                        retry({count: 5, delay: 10000}),
                        catchError(() => [])
                    ),
                    chargedAmount: this._overviewService.getChartChargedAmount(request).pipe(
                        retry({count: 5, delay: 10000}),
                        catchError(() => [])
                    )
                }).pipe(
                    tap((data) => {
                        this._repo.updateNumSessions(data.numSessions)
                        this._repo.updateChargedAmount(data.chargedAmount)
                    }),
                    // elf's trackRequestResult helps to get request states in component
                    trackRequestResult(['overviewPlots'], {skipCache: true}),
                )
            })
        ).subscribe()

        
        // fetch all available filterVariables with new customer
        this._appRepo.selectedCustomer$.pipe(
            takeUntilDestroyed(),
            filter((customer) => customer !== null && customer.identifier !== null && customer.identifier !== ''),
            this._permService.rerunOnPermissionUpdate(),
            switchMap(() => {
                return this._configurationService.getFilterOptions().pipe(
                    tap(this._filtersRepo.updateFilterVariables),
                    trackRequestResult(['filterVariables'], {skipCache: true})
                )
            })
        ).subscribe();

        // timer that resets with new active filters selection, emits every 10 minutes
        const resettableFiltersTimer$ = this._filtersRepo.activeFilterValues$.pipe(
            switchMap(() => interval(REFETCH_FILTERS_INTERVAL)),
            startWith(0)
        )

        // floors min and ceils max values for range filters
        const filterOptionsNormalizer = () => pipe(
            map((filterOptions: FilterOption[]) => {
                const isNum = (x: any): x is Number => typeof(x) == 'number' && !isNaN(x)
                return filterOptions.map((filterOption) => {
                    if (filterOption.filterType == 'range') {
                        const [min, max, receiveNull] = filterOption.options;
                        if (isNum(min) && isNum(max)) {
                            filterOption.options = [Math.floor(min), Math.ceil(max), receiveNull]
                        }
                    }
                    return filterOption
                })
            })
        )

        // get all filter keys from currently selected columns
        const filterIdsFromColumns$ = combineLatest({
            selectedCols: this._repo.selectedColumns$,
            allCols: this._repo.allColumns$,
            filterVars: this._filtersRepo.filterVariables$ 
        }).pipe(
            // this._permService.rerunOnPermissionUpdate(),
            map(({selectedCols, allCols, filterVars}) => {
                const selectedIds = selectedCols.map((sel) => sel.id);
                // get full Filter obj from selected ids above
                const selectedColumns = allCols.filter((col) => selectedIds.includes(col.id));
                // get all featured keys of the selected columns
                const selColKeys = selectedColumns.flatMap((col) => [...(col.keyInArray ?? []), ...(col.keyInConnector ?? [])] as string[]);
                // find column keys in available filterVars
                return filterVars.data.filter((filterVar) => selColKeys.includes(filterVar.key))
            })
        );

        // get all filter keys from currently selected columns and active filters
        const allFilterIds$ = combineLatest({
            filterIdsFromColumns: filterIdsFromColumns$,
            activeFilters: this._filtersRepo.activeFilterValues$.pipe(
                debounceTime(1)
            )
        }).pipe(
            this._permService.rerunOnPermissionUpdate(),
            map(({filterIdsFromColumns, activeFilters}) => {
                // combine all filter Ids of selected filters and present filters from the column selection
                return [
                    ...new Set([
                        ...activeFilters.map((filter) => filter.id),
                        ...filterIdsFromColumns.map((filter) => filter.key)
                    ])
                ];
            })
        )

        // update base filter options with new filterVariable selection, new customer or with new columns selection
        combineLatest({
            customer: this._appRepo.selectedCustomer$,
            filterIds: allFilterIds$
        }).pipe(
            debounceTime(5),
            takeUntilDestroyed(),
            filter(({customer}) => customer !== null && customer.identifier !== null && customer.identifier !== ''),
            withLatestFrom(this._filtersRepo.baseFilterOptions$),
            switchMap(([{filterIds}, baseFilterOptions]) => {
                const baseFilterIds = baseFilterOptions.data.map((filter) => filter.key);
                const newFilterIds = filterIds.filter((filterId) =>
                    !baseFilterIds.includes(filterId)
                )
                // only fetch for new filter values
                return from(newFilterIds).pipe(
                    take(newFilterIds.length),
                    mergeMap((filterId) => {
                        return this._configurationService.getFilterOption({
                            filterVariable: filterId
                        }).pipe(
                            retry({count: 1, delay: 10000}),
                            catchError((error) => {
                                // if somehow the user has no permission, delete the filter from the store
                                if (error.status == 401) {
                                    this._filtersRepo.deleteFilters([filterId]);
                                    this._filtersRepo.deleteBaseFilterOptions([filterId])
                                }
                                return EMPTY
                            })
                        )
                    }),
                    toArray(),
                    filterOptionsNormalizer(),
                    tap((filterOptions) => this._filtersRepo.updateBaseFilterOptions(filterOptions)),
                    trackRequestResult(['baseFilters'], {skipCache: true})
                )
            })
        ).subscribe()

        // refresh all baseFilter options of selected filters with refresh timer
        combineLatest({
            customer: this._appRepo.selectedCustomer$,
            timer: this._refreshCacheTimer$
        }).pipe(
            takeUntilDestroyed(),
            filter(({customer}) => customer !== null && customer.identifier !== null && customer.identifier !== ''),
            withLatestFrom(this._filtersRepo.baseFilterOptions$),
            switchMap(([{customer, timer}, {data}]) => {
                return from(data).pipe(
                    take(data.length),
                    mergeMap((filter) =>
                        this._configurationService.getFilterOption({
                            filterVariable: filter.key
                        }).pipe(
                            catchError((error) => {
                                // if somehow the user has no permission, delete the filter from the store
                                if (error.status == 401) {
                                    this._filtersRepo.deleteFilters([filter.key]);
                                    this._filtersRepo.deleteBaseFilterOptions([filter.key]);
                                }
                                return EMPTY
                            })
                        )
                    ),
                    toArray(),
                    filterOptionsNormalizer(),
                    tap((filterOptions) => this._filtersRepo.updateBaseFilterOptions(filterOptions)),
                    trackRequestResult(['baseFilters'], {skipCache: true})
                )
            })
        ).subscribe()

        // fetch new filter options with new filter selection, update with timer or new customer
        combineLatest({
            activeFilters: this._filtersRepo.activeFilterValues$.pipe(
                debounceTime(1)
            ),
            timer: resettableFiltersTimer$,
            customer: this._appRepo.selectedCustomer$
        }).pipe(
            takeUntilDestroyed(),
            filter(({customer}) => customer !== null && customer.identifier !== null && customer.identifier !== ''),
            distinctUntilChanged((prev, current) => this._compareChanges(prev, current)),
            // get all current base filters to update against
            withLatestFrom(this._filtersRepo.baseFilterOptions$.pipe(
                map(({data}) => data)
            )),
            switchMap(([{activeFilters}, baseFilterOptions]) => {
                // filter out empty activeFilters
                const activeFiltersWithValue = activeFilters.filter((filter) => filter.value !== undefined && filter.value !== null && filter.value.length !== 0);
                // we do not need to fetch any new combinedFilters 
                if (activeFiltersWithValue.length == 0) {
                    return EMPTY
                }

                let request = this._requestBuilder({activeFilters: activeFiltersWithValue}, '');
                return from(baseFilterOptions).pipe(
                    take(baseFilterOptions.length),
                    mergeMap((filter) => {
                        return this._configurationService.getFilterOption({
                            filterVariable: filter.key, filter: request["filter"]
                        }).pipe(
                            retry({count: 1, delay: 10000}),
                            catchError((error) => {
                                // if somehow the user has no permission, delete the filter from the store
                                if (error.status == 401) {
                                    this._filtersRepo.deleteFilters([filter.key]);
                                    this._filtersRepo.deleteBaseFilterOptions([filter.key]);
                                }
                                return EMPTY
                            })
                        )
                    }),
                    toArray(),
                    filterOptionsNormalizer(),
                    tap((filterOptions) => this._filtersRepo.updateCombineableFilterOptions(filterOptions)),
                    trackRequestResult(['combineableFilterOptions'], {skipCache: true})
                )
            })
        ).subscribe()

        // fetch filterSets
        // updates with refreshRev or with new customer
        // only fetches if user has permissions
        this._filterSetsRefresh$.pipe(
            takeUntilDestroyed(),
            this._permService.filterAndRerunOnPermissionUpdate('routes.filterSets'),
            // only fetch if customer is set
            withLatestFrom(this._appRepo.selectedCustomer$),
            filter(([timers, customer]) => customer !== null && customer.identifier !== null && customer.identifier !== ''),
            switchMap(() => {
                return this._filtersetsService.getFilterSets().pipe(
                    switchMap((filterSets) => {
                        const ownerIds = Array.from(new Set(filterSets.map((filterSet) => filterSet.ownerId)));
                        if (ownerIds.length == 0) return of({filterSets, owners: []})
                        return this._userService.getUsersByUuid({uuid: ownerIds}).pipe(
                            catchError(_ => of([])),
                            map((owners) => ({ filterSets, owners }))
                        )
                    }),
                    tap(({ filterSets, owners }) => {
                        const ownerMap = new Map();
                        owners.forEach((owner) => {
                            ownerMap.set(owner.uuid, owner);
                        });        
                        const updatedFilterSets = filterSets.map((filterSet) => {
                            const owner = ownerMap.get(filterSet.ownerId);
                            const ownerName = owner
                                ? `${owner.firstName} ${owner.lastName}`
                                : 'undefined';
                            return {
                                ...filterSet,
                                ownerName: ownerName
                            };
                        });
                        this._filtersRepo.updateFilterSets(updatedFilterSets);
                    }),
                    trackRequestResult(['filterSets'], { skipCache: true })
                );
            })
        ).subscribe();

        // fetch new Alerts either with alertsRefresh or every 5 minutes
        combineLatest([
            this._refreshAlertCacheTimer$,
            this._alertsRefresh$
        ]).pipe(
            takeUntilDestroyed(),
            // only fetch if customer is set
            withLatestFrom(this._appRepo.selectedCustomer$),
            filter(([timers, customer]) => customer !== null && customer.identifier !== null && customer.identifier !== ''),
            this._permService.filterAndRerunOnPermissionUpdate('routes.filterSets'),
            switchMap(() => {
                return this._filtersetsService.getAlerts().pipe(
                    take(1),
                    tap((res) => this._filtersRepo.updateAlerts(res)),
                    trackRequestResult(['alerts'], {skipCache: true})
                )
            })
        ).subscribe()


        // timer resets with new req, then emits every 15 min
        const mapTimerResettable$ = plotChanges$.pipe(
            switchMap(() => interval(REFETCH_MAP_INTERVAL))
        )

        // limits resettableTimer$ to only emit while on map view
        const mapTimerRefetch$ = combineLatest({
            resTimer: mapTimerResettable$,
            currView: navigationEnd$
        }).pipe(
            startWith({resTimer: -1, currView: ''}), // causes fetching at least once
            filter(({resTimer, currView}) => resTimer === -1 || currView === '/map')
        )

        // store last timer count to compare against
        // if change comes from plotChanges$, the timerRef will be the same as from last emission
        // thus we can pass a "silent" flag with the data to not call view update events on map when updating by auto-refresh
        let lastMapTimerRef: number | undefined;
        // station locations data for OV plot and map view
        combineLatest([
            plotChanges$,
            mapTimerRefetch$
        ]).pipe(
            takeUntilDestroyed(),
            distinctUntilChanged(),
            // check again if customer is set
            filter(([{customer}]) => customer !== null && customer.identifier !== null && customer.identifier !== ''),
            withLatestFrom(this._defaultSortingKey$),
            switchMap(([[combined, timerRef], defaultSortingKey]) => {
                // request for stationLocations
                let baseRequest = this._requestBuilder(combined, defaultSortingKey);
                // req w filter for plots differs from getChargingStations
                baseRequest["filters"] = baseRequest["filter"];

                // create separate request for hidden stationLocations
                let hiddenRequest = {...baseRequest};
                hiddenRequest['showOnlyHidden'] = true;

                return forkJoin([
                    this._overviewService.getLocationsOfChargingStations(baseRequest).pipe(
                        catchError(() => []),
                        trackRequestResult(['stationLocations'], {skipCache: true})
                    ),
                    // parallel fetch to get all hidden station locations matching the current filters
                    // only fetch if the baseRequest does not already contain the "showOnlyHidden" flag
                    iif(
                        () => combined.showOnlyHidden,
                        of([]),
                        this._overviewService.getLocationsOfChargingStations(hiddenRequest).pipe(
                            catchError(() => []),
                        )
                    )
                ]).pipe(
                    tap(([base, hidden]) => {
                        let isAutoUpdate = lastMapTimerRef !== undefined && timerRef.resTimer !== lastMapTimerRef;
                        lastMapTimerRef = timerRef.resTimer
                        this._repo.updateStationLocations(base, isAutoUpdate);
                        // update hidden station locations. If the current request already contained the showOnlyHidden 
                        // flag, we'll write the base data to repo
                        this._repo.updateHiddenStationLocations(combined.showOnlyHidden ? base : hidden);
                    })
                );
            })
        ).subscribe()

        /**
         * Seperate fetching of stationLocations, only for the map on the Operator Dashboard while "table map sync" is active.
         * These stationLocations exclude lat and lng filters, thus giving the operator enough data for the current filter set.
         * If we'd use the filtered stationLocations above, we would end up with an empty map (except for the current bounds) and
         * would need to refetch too many times.
         */
        combineLatest({
            plotChanges: plotChanges$.pipe(
                map((plotChanges) => {
                    const strippedChanges = {...plotChanges};
                    // remove lat and lng filters
                    strippedChanges.activeFilters = strippedChanges.activeFilters.filter((filter) => filter.id !== 'longitude' && filter.id !== 'latitude');
                    return strippedChanges
                }),
                // only emit with new values
                distinctUntilChanged((prev, current) => this._compareChanges(prev, current))
            ),
            mapTableSync: this._repo.mapTableSync$
        }).pipe(
            takeUntilDestroyed(),
            // only run when mapTableSync mode is active
            filter(({mapTableSync}) => mapTableSync),
            withLatestFrom(this._defaultSortingKey$),
            switchMap(([{plotChanges}, defaultSortingKey]) => {
                let request = this._requestBuilder(plotChanges, defaultSortingKey);
                request["filters"] = request["filter"];

                return this._overviewService.getLocationsOfChargingStations(request).pipe(
                    retry({count: 2, delay: 10000}),
                    catchError(() => []),
                    tap(this._repo.updateStationLocationsForSync),
                    trackRequestResult(['stationLocationsForSync'], {skipCache: true})
                )
            })
        ).subscribe()

        // fetch (and refresh) all stationLocations available to user
        timer(0, 1800_000).pipe(
            takeUntilDestroyed(),
            this._permService.rerunOnPermissionUpdate(),
            switchMap(() => 
                this._overviewService.getLocationsOfChargingStations().pipe(
                    trackRequestResult(['allStationLocations'], {skipCache: true})
                )
            ),
            tap(this._repo.updateAllStationLocations)
        ).subscribe()
    }

    private _requestBuilder(config: {
        searchQuery?: string,
        activePageNum?: number,
        perPage?: number,
        activeFilters?: any[], 
        sortBy?: string, 
        sortDirection?: string, 
        showOnlyHidden?: boolean,
        searchColumn?: Record<string, string>,
    }, defaultSortingKey: string): any {
        let request: { [key: string]: any } = {};

        const serialize = (obj: any) => {
            let strings = [];
            for (var p in obj) {
                if (obj.hasOwnProperty(p)) strings.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
            return strings.join("&");
        }

        // build request
        request["perPage"]          = config.perPage        ? config.perPage : 100;
        request["search"]           = config.searchQuery    ? config.searchQuery : null;
        request["pageNumber"]       = config.activePageNum  ? config.activePageNum : 1;
        request["sortBy"]           = config.sortBy         ? config.sortBy : defaultSortingKey;
        request["sortOrder"]        = config.sortDirection  ? config.sortDirection  : null;
        request["showOnlyHidden"]   = config.showOnlyHidden

        if (config.searchColumn && Object.keys(config.searchColumn).length > 0) {
            request["searchFields"] = serialize(config.searchColumn);
        }

        if (config.activeFilters) {
            request["filter"] = filterQueryBuilder(config.activeFilters);
        }
        return request
    }

    // returns true if the two arrays are alike (ignores order)
    private _compareArrays(a: any[], b: any[]) {
        if (a.length !== b.length) return false;
        const uniqueValues = new Set([...a, ...b]);
        for (const v of uniqueValues) {
            const aCount = a.filter(e => e === v).length;
            const bCount = b.filter(e => e === v).length;
            if (aCount !== bCount) return false;
        }
        return true;
    }

    // reset if server throws error
    private _resetToDefaultState(currState: {
        searchQuery?: string,
        activeFilters?: any[], 
        sortBy?: string,
        searchColumn?: Record<string, string>
    }, defaultSortingKey: string) {
        this._notificationService.showLocalizedError('DASHBOARD.RESET.ERROR_TEXT', 'DASHBOARD.RESET.ERROR_REQUEST')
        // first try to reset sortBy
        if (currState.sortBy !== defaultSortingKey) {
            this._repo.updateSortBy(defaultSortingKey);
            this._notificationService.showInfo(`${this._translate.instant('DASHBOARD.RESET.RESETTING_SORTING_TO')} ${this._filtersRepo.getFilterName(defaultSortingKey)}`)
            return;
        }
        // if sortby is not set or on second error, reset column search
        if (currState.searchColumn) {
            this._repo.deleteSearchColumn();
            this._notificationService.showLocalizedInfo('DASHBOARD.RESET.RESETTING_SEARCH_COLUMN')
            return;
        }
        // if no column search is set or on third error, reset "main" search query
        if (currState.searchQuery) {
            this._repo.setSearchQuery('')
            this._notificationService.showLocalizedInfo('DASHBOARD.RESET.RESETTING_SEARCH')
            return;
        }
        // if sortby and searchquery arent set or on fourth error, reset activeFilters
        if (currState.activeFilters) {
            let activeFilters = currState.activeFilters.map((filter: {id: string, value: any}) => filter.id);
            this._filtersRepo.deleteFilters(activeFilters)
            this._notificationService.showLocalizedInfo('DASHBOARD.RESET.RESETTING_FILTERS')
            return;
        }

        // if all other cases failed, and default sortBy is applied, try to reconnect
        if (currState.sortBy == defaultSortingKey) {
            this._notificationService.showLocalizedError('DASHBOARD.RESET.TRYING_AGAIN', 'DASHBOARD.RESET.ERROR_CONNECTION')
            setTimeout(() => {
                return;
            }, 5000);
        }

        return false;
    }

    private _compareChanges(prev: any, current: any): boolean {
        const prevKeys: (keyof typeof prev)[] = Object.keys(prev) as (keyof typeof prev)[];

        // compare all previous and current values
        const valuesAreTheSame = prevKeys.map((key) => {
            const prevValue     = prev[key];
            const currentValue  = current[key];

            if (prevValue instanceof Array) {
                return this._compareArrays(prevValue as any[], currentValue as any[])
            } else if (prevValue instanceof Object) {
                // very cheap object comparison
                return JSON.stringify(prevValue) === JSON.stringify(currentValue)
            } else {
                return prevValue === currentValue
            }
        })

        // if all values are the same (true[]), return true
        return valuesAreTheSame.indexOf(false) === -1
    }

    public updateFilterSetsCache() {
        this._filterSetsRefresh$.next(this._filterSetsRefresh$.value + 1);
    }

    public updateAlertsCache() {
        this._alertsRefresh$.next(this._alertsRefresh$.value + 1);
    }
}
