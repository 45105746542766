import { Injectable } from "@angular/core";
import { createStore, select, setProp, setProps, withProps } from "@ngneat/elf";
import {
    addEntities,
    deleteEntities,
    hasEntity,
    updateEntities,
    withEntities,
    selectAllEntities,
    deleteAllEntities,
    getEntity,
    setEntities,
    entitiesPropsFactory,
    getAllEntities
} from "@ngneat/elf-entities";
import {
    persistState,
    localStorageStrategy,
} from '@ngneat/elf-persist-state';
import { joinRequestResult } from "@ngneat/elf-requests";
import { RequestResult } from "@ngneat/elf-requests/src/lib/requests-result";
import { combineLatest, map, Observable, ReplaySubject, share, withLatestFrom } from "rxjs";
import { ChargingStation, ChargedAmount, Connector, NumSessions, OverviewMetaInfo, StationLocations } from "../data-backend/models";
import { TooltipConfig } from "src/app/shared/table/table.component";
import { StationsMapBounds } from "src/app/shared/stations-map/stations-map.component";
import { NotificationState } from "src/app/overview/overview-notifications/overview-notifications.component";

/*  
*   Keeps UI state of overview table
*   - selected columns
*   - pagination
*   - sort by
*   - expanded rows
*   - active search query
*/

export interface ColorOptions {
    // eg:  "Ok", "Failure" | 0 = bad -> 100 = good | cluster | lastChargedEnery
    type: 'eval-categorial' | 'eval-continuus' | 'categorial' | 'continuus' | 'date';
    // for unknown ranges in 'eval-continuus', defines in which order color scheme will be interpreted
    good?: 'high' | 'low';
    // for known ranges or categories
    // bad -> good, eg: [0, 100], ['Failure', 'Potential Failure', 'Ok']
    ranges?: number[] | string[];
}

export interface StationColumn {
    id: number;
    name: string;
    className?: string;
    keyInArray?: (keyof ChargingStation)[];
    selected?: boolean;
    keyInConnector?: (keyof Connector)[];
    valueType?: 'string' | 'number' | 'date' | 'boolean' | 'custom';
    suffix?: string;
    renderCell?: (attr: any[]) => string;
    config?: {
        noMinWidth?: boolean;
        defaultWidth?: number;
        noFilter?: boolean;
        noSort?: boolean;
        noSearch?: boolean;
        sticky?: boolean;
        squished?: 'left' | 'right';
        icon?: {
            type: string;
            color: string;
            size: 'default' | 'large';
        }
        tooltip?: TooltipConfig;
    };
}

// keep stations cached, keep separate from other overview settings, which will be stored in localStorage
const { stationsEntitiesRef, withStationsEntities } = entitiesPropsFactory('stations');
const stationsStore = createStore(
    { name: 'stations' },
    withStationsEntities<ChargingStation, 'stationId'>({ idKey: 'stationId' }),
    withProps<OverviewMetaInfo & {
        stationsAutoUpdated: boolean // true if last update was triggered by timer in cache service
    }>({
        maxPage: 1,
        page: 1,
        perPage: 100,
        stationsAutoUpdated: false
    })
)

const { widthEntitiesRef, withWidthEntities } = entitiesPropsFactory('width');
const { availableColumnsEntitiesRef, withAvailableColumnsEntities } = entitiesPropsFactory('availableColumns');
const { searchColumnsEntitiesRef, withSearchColumnsEntities } = entitiesPropsFactory('searchColumns');
const overviewStore = createStore(
    { name: 'overview' },
    withEntities<{ id: StationColumn['id'] }>(),
    withAvailableColumnsEntities<StationColumn>(),
    withWidthEntities<{ id: StationColumn['id'], width: number }>(),
    withSearchColumnsEntities<{id: StationColumn['id'], value: string}>(),
    withProps<{
        pageNum: number,
        sortBy: string,
        sortDirection: 'asc' | 'desc',
        refetchRev: number, // enables refetching from different components by incrementing
        scrollTop: number | null, // keeping track of scrollposition in table
        scrollLeft: number | null, // keeping track of offset left of table
        fullWidth: boolean, // keep track whether full width mode is activated
        showOnlyHidden: boolean,
        activeView: 'table' | 'map', // viewMode to return to after /details
        searchQuery: string,
        filtersCollapsed: boolean
    }>({
        pageNum: 1,
        sortBy: '',
        sortDirection: 'desc',
        refetchRev: 0,
        scrollTop: null,
        scrollLeft: null,
        fullWidth: false,
        showOnlyHidden: false,
        activeView: 'table',
        searchQuery: '',
        filtersCollapsed: true
    })
)

// cache overview plot data
const { numSessionsEntitiesRef, withNumSessionsEntities } = entitiesPropsFactory('numSessions')
// all available station locations
const { allStationLocationsEntitiesRef, withAllStationLocationsEntities } = entitiesPropsFactory('allStationLocations');
// filtered station locations (except for filters lat and lng) - needed for mapTableSync on Operator Dashboard
const { stationLocationsForSyncEntitiesRef, withStationLocationsForSyncEntities } = entitiesPropsFactory('stationLocationsForSync');
// filtered station locations (except for filters lat and lng) - needed for mapTableSync on Operator Dashboard
const { hiddenStationLocationsEntitiesRef, withHiddenStationLocationsEntities } = entitiesPropsFactory('hiddenStationLocations');
// station locations filtered by station filters
const { stationLocationsEntitiesRef, withStationLocationsEntities } = entitiesPropsFactory('stationLocations')
const { chargedAmountEntitiesRef, withChargedAmountEntities } = entitiesPropsFactory('chargedAmount')
const overviewPlotsStore = createStore(
    { name: 'overviewPlots' },
    withProps<{
        // true if last update was called by autoUpdater
        stationLocationsAutoUpdated: boolean,
        mapTableSync: boolean,
        mapExpanded: boolean,
        stationsMapBounds: StationsMapBounds | null,
        collapsed: boolean
    }>({
        stationLocationsAutoUpdated: false,
        mapTableSync: false,
        mapExpanded: false,
        stationsMapBounds: null,
        collapsed: true
    }),
    withNumSessionsEntities<NumSessions, 'date'>({ idKey: 'date' }),
    withStationLocationsEntities<StationLocations, 'stationId'>({ idKey: 'stationId' }),
    withAllStationLocationsEntities<StationLocations, 'stationId'>({idKey: 'stationId'}),
    withStationLocationsForSyncEntities<StationLocations, 'stationId'>({idKey: 'stationId'}),
    withHiddenStationLocationsEntities<StationLocations, 'stationId'>({idKey: 'stationId'}),
    withChargedAmountEntities<ChargedAmount, 'date'>({ idKey: 'date' })
)

const expandedRowStore = createStore(
    { name: 'expandedRows' },
    withEntities<{ id: ChargingStation['stationId'] }>()
)

const bulkActionRowStore = createStore(
    { name: 'bulkActionRows' },
    withEntities<ChargingStation, 'stationId'>({idKey: 'stationId'})
)

persistState(overviewStore, {
    key: 'overview',
    storage: localStorageStrategy,
});

persistState(expandedRowStore, {
    key: 'expandedRows',
    storage: localStorageStrategy,
});

persistState(bulkActionRowStore, {
    key: 'bulkActionRowStore',
    storage: localStorageStrategy,
});

// notifications state
const notificationStatesStore = createStore(
    { name: 'form'},
    withEntities<NotificationState>()
);

persistState(notificationStatesStore, {
    key: 'notificationStatesStore',
    storage: localStorageStrategy,
});

@Injectable({ providedIn: 'root' })
export class overviewRepository {
    // overview plot data
    overviewPlotsData$ = combineLatest({
        numSessions: overviewPlotsStore.pipe(selectAllEntities({ ref: numSessionsEntitiesRef })),
        chargedAmount: overviewPlotsStore.pipe(selectAllEntities({ ref: chargedAmountEntitiesRef }))
    }).pipe(
        joinRequestResult(['overviewPlots'])
    )
    // only cached plot data for stationLocations
    stationLocations$: Observable<RequestResult<any> & {
        data: {
            stationLocations: StationLocations[],
            isAutoUpdate: boolean
        }
    }> = overviewPlotsStore.pipe(
        selectAllEntities({ ref: stationLocationsEntitiesRef }),
        withLatestFrom(overviewPlotsStore.pipe(
            select(state => state.stationLocationsAutoUpdated)
        )),
        map(([stationLocations, isAutoUpdate]) => {
            return {
                stationLocations: stationLocations,
                isAutoUpdate: isAutoUpdate
            }
        }),
        joinRequestResult(['stationLocations']),
        share({ connector: () => new ReplaySubject(1) })
    );
    // update each plot store
    updateNumSessions(numSessions: NumSessions[]) {
        overviewPlotsStore.update(
            setEntities(numSessions, { ref: numSessionsEntitiesRef })
        )
    }
    // all station locations matching set filters
    updateStationLocations(stationLocations: StationLocations[], isAutoUpdate: boolean) {
        overviewPlotsStore.update(
            setEntities(stationLocations, { ref: stationLocationsEntitiesRef }),
            setProp('stationLocationsAutoUpdated', isAutoUpdate)
        )
    }
    updateChargedAmount(chargedAmount: ChargedAmount[]) {
        overviewPlotsStore.update(
            setEntities(chargedAmount, { ref: chargedAmountEntitiesRef })
        )
    }
    // update cached overview plots data
    updateOverviewPlotsData(data: {
        numSessions: NumSessions[],
        stationLocations: StationLocations[],
        chargedAmount: ChargedAmount[]
    }) {
        overviewPlotsStore.update(
            setEntities(data.numSessions, { ref: numSessionsEntitiesRef }),
            setEntities(data.chargedAmount, { ref: chargedAmountEntitiesRef })
        )
    }

    // ALL station locations (not filtered by station filters)
    allStationLocations$ = overviewPlotsStore.pipe(
        selectAllEntities({ref: allStationLocationsEntitiesRef}),
        joinRequestResult(['allStationLocations'])
    )
    /**
     * Updates stored data for *all* station locations (= not filtered by any searches or filters on Dashboard)
     */
    updateAllStationLocations(stationLocations: StationLocations[]) {
        overviewPlotsStore.update(
            setEntities(stationLocations, {ref: allStationLocationsEntitiesRef})
        )
    };

    /**
     * Filtered station locations for tableMapSync. Filtered by all active filters, except lat and lng.
     */
    stationLocationsForSync$ = overviewPlotsStore.pipe(
        selectAllEntities({ref: stationLocationsForSyncEntitiesRef}),
        joinRequestResult(['stationLocationsForSync'])
    );
    updateStationLocationsForSync(stationLocations: StationLocations[]) {
        overviewPlotsStore.update(
            setEntities(stationLocations, {ref: stationLocationsForSyncEntitiesRef})
        );
    };

    /**
     * station locations matching the set filters with "hidden" flag
     */
    public hiddenStationLocations$ = overviewPlotsStore.pipe(
        selectAllEntities({ref: hiddenStationLocationsEntitiesRef})
    );
    updateHiddenStationLocations(stationLocations: StationLocations[]) {
        overviewPlotsStore.update(
            setEntities(stationLocations, { ref: hiddenStationLocationsEntitiesRef })
        );
    };

    // controls collapsed state of dashboard plots
    public plotsCollapsed$ = overviewPlotsStore.pipe(
        select(({collapsed}) => collapsed)
    )
    public setPlotsCollapsed(state: boolean) {
        overviewPlotsStore.update(
            setProp('collapsed', state)
        )
    }

    // controls sync of map boundaries with active filters
    public mapTableSync$ = overviewPlotsStore.pipe(
        select(({mapTableSync}) => mapTableSync)
    )
    public toggleMapTableSync() {
        overviewPlotsStore.update(
            setProp('mapTableSync', !overviewPlotsStore.getValue().mapTableSync)
        )
    }

    // controls sync of map boundaries with active filters
    public mapExpanded$ = overviewPlotsStore.pipe(
        select(({mapExpanded}) => mapExpanded)
    )
    public toggleMapExpanded() {
        overviewPlotsStore.update(
            setProp('mapExpanded', !overviewPlotsStore.getValue().mapExpanded)
        )
    }

    // current zoom (lat min / max, lng min / max) of dashboard map
    public stationsMapBounds$ = overviewPlotsStore.pipe(
        select(({ stationsMapBounds }) => stationsMapBounds)
    )
    public setStationMapBounds(bounds: StationsMapBounds) {
        overviewPlotsStore.update(
            setProp('stationsMapBounds', bounds)
        )
    }

    // get last active view (map or table)
    activeView$: Observable<'table' | 'map'> = overviewStore.pipe(
        select((state) => state.activeView)
    )
    setActiveView(view: 'table' | 'map') {
        overviewStore.update(setProp('activeView', view))
    }

    private _stationsAutoUpdated$ = stationsStore.pipe(
        select(state => state.stationsAutoUpdated)
    )

    private _stationsData$ = stationsStore.pipe(
        selectAllEntities({ ref: stationsEntitiesRef })
    );

    // all stored stations
    // updated by OverviewCacheService
    stations$ = combineLatest([
        this._stationsData$,
        this._stationsAutoUpdated$
    ]).pipe(
        // join with req from cache service, observes state
        joinRequestResult(['stations']),
        map((joinedRequestResult) => {
            const [stations, isAutoUpdate] = joinedRequestResult.data;
            (joinedRequestResult as any).data = stations;
            (joinedRequestResult as any).isAutoUpdate = isAutoUpdate;

            return joinedRequestResult as any as RequestResult<any, any> & {data: ChargingStation[], isAutoUpdate: boolean}
        }),
        share({connector: () => new ReplaySubject(1)})
    )
    // latest meta for OV
    stationsMeta$: Observable<OverviewMetaInfo> = stationsStore.pipe(
        select(({ maxPage, perPage, page }) => {
            return {
                maxPage: maxPage,
                perPage: perPage,
                page: page
            }
        })
    )
    // set new available columns
    public setAvailableColumns(columns: StationColumn[]) {
        // set to store
        overviewStore.update(
            setEntities(columns, {ref: availableColumnsEntitiesRef})
        )
        // if no selected cols are currently stored, get all columns with selected flag to set default selection
        if (overviewStore.query(getAllEntities()).length == 0) {
            const selectedIds = columns.filter(c => c.selected).map((c: StationColumn) => c.id);
            this.setColumns(selectedIds)
        }
    }
    // all available columns for overview
    allColumns$ = overviewStore.pipe(
        selectAllEntities({ ref: availableColumnsEntitiesRef })
    );
    selectedColumns$ = overviewStore.pipe(
        selectAllEntities()
    );
    columnWidths$ = overviewStore.pipe(
        selectAllEntities({ ref: widthEntitiesRef })
    )
    // search in specific column
    searchColumns$ = overviewStore.pipe(
        selectAllEntities({ ref: searchColumnsEntitiesRef })
    )
    updateSearchColumn(colId: StationColumn['id'], value: string) {
        const hasColId = overviewStore.query(hasEntity(colId, {ref: searchColumnsEntitiesRef}));
        overviewStore.update(
            // add or update column search
            hasColId 
                ? updateEntities(colId, {value: value}, {ref: searchColumnsEntitiesRef})
                : addEntities({id: colId, value: value}, {ref: searchColumnsEntitiesRef}),
            setProp('pageNum', 1)
        )
    }
    deleteSearchColumn(colId?: StationColumn['id']) {
        if (colId == undefined) {
            overviewStore.update(deleteAllEntities({ref: searchColumnsEntitiesRef}))
        } else {
            overviewStore.update(
                deleteEntities(colId, {ref: searchColumnsEntitiesRef})
            )
        }
    }
    // expanded rows in dashboard overview
    allExpandedRows$ = expandedRowStore.pipe(
        selectAllEntities(),
        map(id => id.map(({ id }) => id))
    );
    // Last saved pagination page
    activePageNum$ = overviewStore.pipe(
        select((state) => state.pageNum)
    );
    // Last saved sortby
    sortBy$ = overviewStore.pipe(
        select((state) => state.sortBy)
    )
    // direction of sortBy
    sortDirection$ = overviewStore.pipe(
        select((state) => state.sortDirection)
    )
    // full width mode
    fullWidth$ = overviewStore.pipe(
        select((state) => state.fullWidth)
    )
    // optional revision of fetch
    refetchRev$ = overviewStore.pipe(
        select((state) => state.refetchRev)
    )
    // return all saved Stations of selected rows
    allbulkActionRows$ = bulkActionRowStore.pipe(
        selectAllEntities()
    )
    // return all saved Stations IDs of selected rows
    allbulkActionRowIDs$ = bulkActionRowStore.pipe(
        selectAllEntities(),
        map((stations) => stations.map(({stationId}) => stationId))
    )
    // Search
    searchQuery$ = overviewStore.pipe(
        select((state) => state.searchQuery)
    )

    // scroll offset of overview table
    getScrollOffset() {
        let state = overviewStore.getValue()
        return { top: state.scrollTop, left: state.scrollLeft }
    }

    // include hidden stations in fetch for all stations
    showOnlyHidden$ = overviewStore.pipe(
        select((state) => state.showOnlyHidden)
    )

    // replaces all currently stored stations with new set
    updateStationCache(stations: ChargingStation[] | undefined) {
        if (stations === undefined) return
        stationsStore.update(
            setEntities(stations, { ref: stationsEntitiesRef })
        )
    }

    // updates autoUpdate flag for stations fetch in repo
    // needs to be done separately, as we need to set this flag before starting the autoUpdate fetch
    updateStationAutoUpdateFlag(isAutoUpdate: boolean = false) {
        stationsStore.update(
            setProp('stationsAutoUpdated', isAutoUpdate),
        )
    }

    updateMetaCache(meta: OverviewMetaInfo | undefined) {
        if (meta === undefined) return
        stationsStore.update(
            setProps({
                maxPage: meta.maxPage,
                page: meta.page,
                perPage: meta.perPage
            })
        )
    }

    // Columns
    hasColumn(columnId: StationColumn['id']): boolean {
        return overviewStore.query(hasEntity(columnId))
    }

    addColumn(columnId: StationColumn['id']) {
        overviewStore.update(addEntities({ id: columnId }))
    }

    removeColumn(columnId: StationColumn['id']) {
        overviewStore.update(deleteEntities(columnId))
    }

    toggleColumn(columnId: StationColumn['id']) {
        this.hasColumn(columnId) ? this.removeColumn(columnId) : this.addColumn(columnId);
    }

    // updates all selected column ids
    setColumns(columnIds: StationColumn['id'][]) {
        overviewStore.update(
            setEntities(columnIds.map((c) => Object.assign({id: c})))
        )
    }

    // set or update width of column in view
    setColumnWidth(columndId: StationColumn['id'], width: number) {
        if (overviewStore.query(hasEntity(columndId, { ref: widthEntitiesRef }))) {
            overviewStore.update(updateEntities(columndId, { width: width }, { ref: widthEntitiesRef }))
        } else {
            overviewStore.update(addEntities({ id: columndId, width: width }, { ref: widthEntitiesRef }))
        }
    }

    getColumnWidth(columnId: StationColumn['id']) {
        return overviewStore.query(getEntity(columnId, { ref: widthEntitiesRef }))?.width || null
    }

    // Expanded rows
    toggleExpandedRow(stationId: ChargingStation['stationId']) {
        if (expandedRowStore.query(hasEntity(stationId))) {
            // remove from storage if id already exists
            expandedRowStore.update(deleteEntities(stationId))
        } else {
            // add to storage
            expandedRowStore.update(addEntities({ id: stationId }))
        }
    }

    // increment revision to refetch overview
    incrementRefetchRev() {
        let currRev = overviewStore.state.refetchRev;
        overviewStore.update(setProp('refetchRev', ++currRev))
    }

    // Pagination
    updatePagination(pageNum: number) {
        overviewStore.update(setProp('pageNum', pageNum))
    }

    // SortBy
    updateSortBy(attribute: string | undefined, direction?: 'asc' | 'desc') {
        if (!attribute) return
        const currentSortBy = overviewStore.getValue().sortBy;
        const currentSortDirection = overviewStore.getValue().sortDirection;

        let sortDirection: 'asc' | 'desc';
        if (direction) {
            // set passed direction
            sortDirection = direction;
            // clear sorting if same attribute and direction as previously
            if (currentSortBy == attribute && currentSortDirection == sortDirection) {
                attribute = '';
                sortDirection = 'desc';
            }
        } else {
            // toggle sortOrder by default
            sortDirection = currentSortBy == attribute && currentSortDirection == 'desc' ? 'asc' : 'desc';
        }

        overviewStore.update(setProp('sortBy', attribute))
        overviewStore.update(setProp('sortDirection', sortDirection))
    }
    updateSortDirection(direction: 'asc' | 'desc') {
        overviewStore.update(setProp('sortDirection', direction))
    }

    // bulkActions
    toggleBulkActionRow(station: ChargingStation) {
        if (bulkActionRowStore.query(hasEntity(station.stationId))) {
            // Remove from storage if id exists
            bulkActionRowStore.update(deleteEntities(station.stationId))
        } else {
            // Add to storage
            bulkActionRowStore.update(addEntities(station))
        }
    }

    // remove all selections
    emptyAllBulkActionRows() {
        bulkActionRowStore.update(deleteAllEntities());
    }

    // update many entities
    updateBulkActionRows(stations: Array<ChargingStation>) {
        bulkActionRowStore.update(addEntities(stations))
    }

    // Search query
    setSearchQuery(input: string) {
        overviewStore.update(setProp('searchQuery', input))
        // update pageNum back to 1 for new query
        overviewStore.update(setProp('pageNum', 1))
    }
    deleteSearchQuery() {
        overviewStore.update(setProp('searchQuery', ''))
        // update pageNum back to 1 for new request
        overviewStore.update(setProp('pageNum', 1))
    }

    // whether filters are collapsed or not
    filtersCollapsed$ = overviewStore.pipe(
        select((state) => state.filtersCollapsed)
    )

    setFiltersCollapsed(collapsed: boolean) {
        overviewStore.update(setProp('filtersCollapsed', collapsed))
    }

    // scrollTop overview table
    setOffsetTop(offset: number) {
        overviewStore.update(setProp('scrollTop', offset))
    }

    setOffsetLeft(offset: number) {
        overviewStore.update(setProp('scrollLeft', offset))
    }

    // set full width mode
    setFullWidth(isActive: boolean) {
        overviewStore.update(setProp('fullWidth', isActive))
    }

    // toggle table fullscreen mode
    toggleFullWidth() {
        overviewStore.update(setProp('fullWidth', !overviewStore.getValue().fullWidth))
    }

    toggleShowOnlyHidden() {
        let currentState = overviewStore.getValue().showOnlyHidden;
        overviewStore.update(setProp('showOnlyHidden', !currentState))
    }

    updateShowOnlyHidden(value: boolean) {
        overviewStore.update(setProp('showOnlyHidden', value))
    }

    updateColumnOrder(ids: StationColumn['id'][]) {
        overviewStore.update(setEntities(ids.map(x => { return { id: x } })))
    }

    // notifiations
    notificationStates$ = notificationStatesStore.pipe(
        selectAllEntities()
    );

    setNotificationState(state: NotificationState) {
        if (notificationStatesStore.query(hasEntity(state.id))) {
            notificationStatesStore.update(updateEntities(state.id, state))
        } else {
            notificationStatesStore.update(addEntities(state))
        }
    }
}
