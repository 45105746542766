import { DOCUMENT } from "@angular/common";
import { Directive, ElementRef, HostListener, Inject, Input, OnDestroy, Renderer2 } from "@angular/core";

@Directive({
    selector: '[tooltip]'
})
// based on https://javascript.plainenglish.io/creating-a-tooltip-directive-in-angular-abfc607d52f3
export class TooltipDirective implements OnDestroy {
    @Input() tooltip: string | null = ''; // The text for the tooltip to display
    @Input() delay?: number = 140; // Optional delay input, in ms
    @Input() tooltipParent?: HTMLElement; // Optional parent to append to
    @Input() toSide: 'top' | 'right' | 'bottom' | 'left' = 'top';
    @Input() textAlign: 'center' | 'left' = 'center';
    @Input() size: 'large' | 'small' = 'large';
    @Input() width?: number;

    private myPopup!: any;
    private timer!: any;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document
    ) { }

    ngOnDestroy(): void {
        if (this.myPopup) { this.myPopup.remove() }
    }

    @HostListener('mouseenter') onMouseEnter() {
        this.timer = setTimeout(() => {
            let x, y;

            const clientRect = this.el.nativeElement.getBoundingClientRect();

            switch (this.toSide) {
                case 'top':
                    x = clientRect.left + (clientRect.offsetWidth ?? clientRect.width) / 2; // Get the middle of the element
                    y = clientRect.top;
                    break;
                case 'bottom':
                    x = clientRect.left + (clientRect.offsetWidth ?? clientRect.width) / 2; // Get the middle of the element
                    y = clientRect.bottom;
                    break;
                case 'left':
                    x = clientRect.left;
                    y = clientRect.bottom - (clientRect.offsetHeight ?? clientRect.height) / 2;
                    break;
                case 'right':
                    x = clientRect.right;
                    y = clientRect.bottom - (clientRect.offsetHeight ?? clientRect.height) / 2;
                    break;
            }

            this.createTooltipPopup(x, y);
        }, this.delay)
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (this.timer) clearTimeout(this.timer);
        if (this.myPopup) { this.myPopup.remove() }
    }

    private createTooltipPopup(x: number, y: number) {
        if (this.tooltip == null) return
        let popup           = this.renderer.createElement('div'),
            toSideClass     = "to-"+this.toSide
        popup.innerHTML     = this.tooltip;
        // hide first to get clientHeight without weird visible behaviour of tooltip
        popup.style.visibility = "hidden";

        if (this.tooltipParent) {
            popup.setAttribute("class", "tooltip-container-absolute " + toSideClass);
            this.renderer.appendChild(this.tooltipParent, popup);
        } else {
            popup.setAttribute("class", "tooltip-container " + toSideClass);
            this.renderer.appendChild(this.document.body, popup);
        }

        if (this.size === "small")      popup.classList.add('small');
        if (this.textAlign === "left")  popup.classList.add('text-left');
        if (this.width)                 popup.style.maxWidth = this.width + "px";
    
        let popupRect = popup.getBoundingClientRect()
        switch (this.toSide) {
            case 'top':
                y -= popupRect.height + 5;
                break;
            case 'bottom':
                y += 10;
                break;
            case 'left':
                x -= popupRect.width / 2;
                y -= popupRect.height / 2;
                break;
            case 'right':
                x += popupRect.width / 2;
                y -= popupRect.height / 2;
                break;
        }
        popup.style.top = y + "px";
        popup.style.left = x + "px";
        popup.style.visibility = "visible";

        this.myPopup = popup;
        setTimeout(() => {
            if (this.myPopup) this.myPopup.remove();
        }, 5000); // Remove tooltip after 5 seconds
    }
}
