import { Injectable, OnDestroy } from "@angular/core";
import { Paths, RolePermissions } from "src/assets/files/roles_access/roles-permissions.types";
import { appRepository } from "../stores/app.repository";
import { catchError, combineLatest, combineLatestWith, EMPTY, filter, firstValueFrom, map, Observable, pipe, shareReplay, startWith, Subject, switchMap, takeUntil, tap, withLatestFrom } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ChargingStation, Connector, RoleTypes } from "../data-backend/models";
import { overviewRepository, StationColumn } from "../stores/overview.repository";
import { ColumnService } from "../helpers/column.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class PermissionsService implements OnDestroy {
    private readonly _destroying$ = new Subject<void>();
    public userPermissions$: Observable<RolePermissions[]>;
    private _userPermissions: RolePermissions[] = [];

    constructor(
        private _http: HttpClient,
        private _overviewRepo: overviewRepository,
        private _appRepo: appRepository,
        private _columnService: ColumnService,
        private _translate: TranslateService
    ) {
        const highestUserRole$ = this._appRepo.currentUser$.pipe(
            map((user) => {
                const isRole = (role: RoleTypes) => user?.roles.includes(role);
                // returns highest role
                return isRole(RoleTypes.UserAdmin) || isRole(RoleTypes.TenantGroupAdmin) 
                    ? 'user_admin' : isRole(RoleTypes.Operator)
                        ? 'operator' 
                        : isRole(RoleTypes.Guest) || isRole(RoleTypes.Support) 
                            ? 'guest' : null;
            })
        )

        // fetches permissions for each role in a single object, "source role" is referenced
        const permissionsByRole$: Observable<Record<string, RolePermissions>> = this._appRepo.currentUser$.pipe(
            switchMap((user) => {
                if (!user) return []

                let roleQuery: Record<(RoleTypes | string), Observable<RolePermissions>> = {};
                for (let userRole of user.roles) {
                    roleQuery[userRole] = this._http.get(`${window.location.origin}/assets/files/roles_access/${userRole}.json`).pipe(
                        catchError(_ => EMPTY)
                    )
                }
                return combineLatest(roleQuery)
            }),
            // set initial permissions for helpers
            tap((roleQuery) => {
                this._userPermissions = Object.values(roleQuery);
            }),
            shareReplay()
        )


        // remove referenced role, only provide sets of permissions
        this.userPermissions$ = permissionsByRole$.pipe(
            map((permissions) => Object.values(permissions))
        )

        const currentTableConfiguration$ = combineLatest({
            sortBy: this._overviewRepo.sortBy$,
            selectedColumns: this._overviewRepo.selectedColumns$
        })
        
        // handle available overview columns based on highest role
        combineLatest({
            permissions: this.userPermissions$,
            newLang: this._translate.onLangChange.pipe(startWith(null))
        }).pipe(
            takeUntil(this._destroying$),
            switchMap(({permissions}) => {
                // get unique filenames, take first, if cant determined, get default
                let fileNames = permissions.map((permission) => this.resolvePath('dashboard.table.availableColumns', permission)) as (string | undefined | boolean)[];
                fileNames = fileNames.filter((name) => name !== undefined && typeof (name) !== "boolean");
                let unique = [...new Set(fileNames)];
                if (unique.length === 0) unique = ['default-columns.json'];

                return this._http.get(`${window.location.origin}/assets/files/dashboard_columns/${unique[0]}`).pipe(
                    map((columns) => {
                        // index all returned columns
                        const updatedColumns = (columns as StationColumn[]).map((value, index: number) => {
                            const nameMapping = value.name;
                            value['id'] = index;
                            if (value.className !== undefined) {
                                value['renderCell'] = (attr: any[]) => this._columnService.getRenderCellFunction(value.className!, attr);
                            } else if (value.valueType === 'date') {
                                value['renderCell'] = (attr: any[]) => this._columnService.getRenderCellFunction('default-date', attr);
                            }
                            value['name'] = this._translate.instant(`DASHBOARD.COLUMNS.${nameMapping}.TITLE`);
                            if (value.config?.tooltip?.text) {
                                value.config.tooltip.text = this._translate.instant(`DASHBOARD.COLUMNS.${nameMapping}.TOOLTIP`);
                            }
                            return value as StationColumn
                        });
                        // reset any column selection if user is not allowed to (i.e. user role changed from operator to guest)
                        let columnSelector = this.checkPermissions('dashboard.table.columnSelector', permissions)
                        if (!columnSelector) {
                            this._overviewRepo.setColumns([])
                        }
                        // set available columns
                        this._overviewRepo.setAvailableColumns(updatedColumns);
                        // return permissions and updated columns for next step
                        return {permissions, updatedColumns};
                    })
                )
            }),
            // get latest table config, which was updated previously by the setAvailableColumns method
            withLatestFrom(currentTableConfiguration$),
            tap(([{permissions, updatedColumns}, {sortBy, selectedColumns}]) => {
                // check if user has a configured sortBy and this config is appliccable to columns
                // else check for featured states and apply lastOverallState or first available featuredState
                const selectedColIds = selectedColumns.map((col) => col.id);
                const selectedCols = updatedColumns.filter((col) => selectedColIds.includes(col.id));
                const selectedKeys = selectedCols.flatMap((col) => [...(col.keyInArray ?? []), ...(col.keyInConnector ?? [])]);
                const sortByInVisibleColumn = selectedKeys.includes(sortBy as (keyof ChargingStation | keyof Connector));
                if (sortBy === '' || !sortByInVisibleColumn) {
                    // updates default sorting key based on featured states of current permissions-set
                    // get single array of all roles featured states
                    const featuredStates = permissions.flatMap((permissionsSet) =>
                        this.resolvePath('global.featuredStates', permissionsSet)
                    ).filter((x) => x) as string[];
                    // use first item in array unless lastOverallState is available
                    const defaultSorting = featuredStates.includes('lastOverallState') ? 'lastOverallState' : featuredStates[0];
                    this._overviewRepo.updateSortBy(defaultSorting)
                }
            })
        ).subscribe()
    }

    /**
     * The function `resolvePath` takes a path and a permissions object, and returns the value at the
     * specified path within the permissions object.
     * @param path - The `path` parameter is of type `Paths<RolePermissions>`. It represents a string that
     * specifies the path to a property within the `permissions` object.
     * @param {RolePermissions} permissions - The `permissions` parameter is of type `RolePermissions`. It
     * represents the permissions assigned to a specific role.
     * @returns the value of the property specified by the path in the permissions object.
     */
    public resolvePath(path: Paths<RolePermissions>, permissions: RolePermissions): Partial<RolePermissions> {
        if (!path) return {}
        return path.split('.').reduce((obj: any, key: string) => obj && obj[key], permissions)
    }

    /**
     * The function `checkPermissions` checks if a given path has the required permissions.
     * @param path - The `path` parameter is of type `Paths<RolePermissions>`, which means it
     * represents a path to a specific resource or action in your application. It is expected to be a
     * valid path that can be resolved to a specific permission.
     * @param {RolePermissions[]} rolePermissionsList - The `rolePermissionsList` parameter is of type `RolePermissions[]`.
     * It represents the permissions that are required to access a certain path.
     * @returns a boolean value.
     */
    public checkPermissions(path: Paths<RolePermissions>, rolePermissionsList: RolePermissions[]): boolean {
        for (let rolePermissions of rolePermissionsList) {
            const match = this.resolvePath(path, rolePermissions);
            const matchType = typeof(match);
            // defaults to true if match undefined, false if path is not of type properly resolved
            if ((matchType === 'undefined' || matchType === 'object'
                ? true
                : matchType === 'boolean'
                    ? match
                    : false) as boolean) {
                return true
            }
        }
        return false
    }

    /**
     * The function `getPermissions` returns the user's permissions.
     * @returns the permissions array
     */
    public getPermissions(path?: Paths<RolePermissions>): Partial<RolePermissions>[] {
        return path && this._userPermissions 
            ? this._userPermissions.map((permission) => this.resolvePath(path, permission)) 
            : this._userPermissions
    }

    /**
     * CAUTION! Might return unexpected merge results where not properly tested!
     * The function `getMergedPermissions` returns a merged object of permissions based on the given
     * path.
     * @param [path] - The `path` parameter is an optional parameter of type `Paths<RolePermissions>`.
     * It represents the path to a specific role's permissions.
     * @returns a partial object of type `RolePermissions`.
     */
    public getMergedPermissions(path?: Paths<RolePermissions>): Partial<RolePermissions> {
        const perms = this.getPermissions(path);
        return Object.assign({}, ...perms);
    }

    /**
     * The `awaitPermissions` function returns the first value emitted by the `userPermissions$`
     * observable.
     * @returns The `awaitPermissions` function is returning the first value emitted by the
     * `userPermissions$` observable.
     */
    public async awaitPermissions() {
        return firstValueFrom(this.userPermissions$)
    }

    /**
     * The function "hasPermission" checks if a user has permission to access a specific path based on
     * their role permissions.
     * @param path - A generic type `Paths` that represents a path to a resource or action. It is expected
     * to be an array of `RolePermissions` objects.
     * @returns A boolean value is being returned.
     */
    public hasPermission(path: Paths<RolePermissions>): boolean {
        return this.checkPermissions(path, this.getPermissions())
    }

    /**
     * The function checks if a given state is available based on the available states permissions.
     * @param {'lastOverallState' | 'lastHeartbeatState' | 'lastChargingModelState' | 'lastErrorState' |
     * 'lastHealthIndexValue'} state - The state parameter is a string that can have one of the
     * following values: 'lastOverallState', 'lastHeartbeatState', 'lastChargingModelState',
     * 'lastErrorState', or 'lastHealthIndexValue'.
     * @returns a boolean value.
     */
    public hasStateModel(state: 'lastOverallState' | 'lastHeartbeatState' | 'lastChargingModelState' | 'lastErrorState' | 'lastHealthIndexValue'): boolean {
        const availableStates = this.getPermissions('global.availableStates');
        const combined = [...new Set(availableStates.flatMap(x => x))].filter((x) => x !== undefined);
        return combined.length === 0
            ? true
            : (combined as string[]).includes(state)
    }

    /**
    * filterAndRerunOnPermissionUpdate is a filtering operator, which will only emit values if the
    * requested permPath is evaluated to true. Can be included in any pipe, which is independent of the user or permission data
    * but needs to be run again once the user permissions are updated. Input = Output, all permission data is stripped
    * @param permPath - the permission path to be tested
    */
    public filterAndRerunOnPermissionUpdate = <T>(permPath: Paths<RolePermissions>) => pipe(
        combineLatestWith<T, [RolePermissions[]]>(this.userPermissions$), // will rerun pipe where implemented with new set of userPermissions
        filter(_ => this.hasPermission(permPath)), // use helper method and "ignore" the userPermissions$ emission. Permissions are already avaiable here. Saves us some duplicate code
        map(([value, _userPermissions]: [T, RolePermissions[]]) => value)
    )

    /**
     * Small helper operator that reruns the pipe where it's implemented once user permissions are updated
     * Can be useful where a pipe result is dependent on the user permissions but e.g. applies some additional filtering
     */
    public rerunOnPermissionUpdate = <T>() => pipe(
        combineLatestWith<T, [RolePermissions[]]>(this.userPermissions$), // will rerun pipe where implemented with new set of userPermissions
        map(([value, _userPermissions]: [T, RolePermissions[]]) => value)
    )

    /**
     * Small helper operator that allows to get the permission value and check if the user has permission in any pipe
     * Returns the actual pipe value and the permission in a object for better readability
     * @param permPath - the permission path to be tested
     * @returns Object with pipe value (key 'value') and userPermission (key 'hasPermission', type boolean)
     */
    public getPermissionAndRerunOnPermissionUpdate = <T>(permPath: Paths<RolePermissions>) => pipe(
        combineLatestWith<T, [RolePermissions[]]>(this.userPermissions$),
        map(([value, userPermissions]) => ({
            value,
            hasPermission: this.hasPermission(permPath)
        }))
    )

    /**
     * Method to create an observable of a specific user permissions result. Updates with new user permissions.
     * Helpful when you need to subscribe to a permission result in a component
     * @param permPath - the permission path to be tested
     * @returns Observable of requested rolePermission result
     */
    public ofPermission = (permPath: Paths<RolePermissions>): Observable<boolean> => this.userPermissions$.pipe(
        map((userPermissions) => this.hasPermission(permPath))
    )

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();        
    }
}
