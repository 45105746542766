import { Injectable, OnDestroy } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { authCodeFlowConfig } from "../../auth.config";
import { environment } from "src/environments/environment";
import { appRepository } from "../stores/app.repository";
import {
    combineLatest,
    map,
    Observable,
    ReplaySubject,
    share,
    skip,
    startWith,
    Subject,
    switchMap,
    takeUntil,
    tap,
    throwError,
    timer,
} from "rxjs";
import { formatDate } from "@angular/common";
import packageJson from "package.json";

@Injectable({
    providedIn: 'root'
})
export class AppAuthService implements OnDestroy {
    private readonly _destroying$ = new Subject<void>();
    private _refreshTimer$: Observable<number>;
    public isLoggedIn$: Observable<boolean>;

    constructor(
        private _oAuthService: OAuthService,
        private _appRepo: appRepository
    ) {
        // timer for refreshing the access token
        const interval = environment.accessTokenRefreshInterval;
        this._refreshTimer$ = timer(0, interval);

        this.isLoggedIn$ = combineLatest([
            this._oAuthService.events,
            this._refreshTimer$
        ]).pipe(
            tap(([event, timer]) => {
                if (event.type == "session_terminated") {
                    // only if "sessionChecksEnabled" is set to true in auth.config
                    // delete all stored data in session and local storage
                    this._appRepo.resetStores();
                }
            }),
            map(() => this._oAuthService.hasValidAccessToken()),
            startWith(false),
            share({connector: () => new ReplaySubject(1)})
        );

        // refreshes token after a given interval (configured in eon.env)
        // used as workaround as CIAM does not provide proper expiration date
        this._refreshTimer$.pipe(
            takeUntil(this._destroying$),
            // skip first emission, as oAuth would not be configured yet
            skip(1),
            tap(() => this._oAuthService.refreshToken({ setScope: false }))
        ).subscribe()
    }

    public configure() {
        this._oAuthService.configure(authCodeFlowConfig);
        this._oAuthService.clientId = environment.clientId;
        console.log(`%cYou're boosting availability with ${environment.brandName}: v${packageJson.version}`, "color: #4895ef;");
        console.log(`%cappID ${environment.appId}`, 'color: #4895ef;')

        // refresh tokens, method was patched to enable additional options
        // currently disabled, as we dont receive token expiration dates
        // this._oAuthService.setupAutomaticSilentRefresh({setScope: false});

        this.login();
    }
    
    
    /**
     * The `_logout` function removes selected customer data, clears session storage, resets stored
     * data, logs out the user, and redirects them to a specified URL.
     * @param {boolean} [reLoginViaPortal=false] - reLoginViaPortal is a boolean parameter that
     * determines whether the user should be redirected to the login portal after logging out. If
     * reLoginViaPortal is true, the user will be redirected to the portal URL specified in the
     * environment variable. If reLoginViaPortal is false, the user will be redirected to the constructed
     * redirect URL.
     */
    private _logout(reLoginViaPortal: boolean = false) {
        // reset post login url if it contains a details route
        // we can't really delete it here as it may be overwritten again through the logout process
        const currentPostLoginURL = sessionStorage.getItem('postLoginURL');
        if (currentPostLoginURL && currentPostLoginURL.includes('/details')) {
            sessionStorage.setItem('postLoginURL', '/overview');
        }
        this._appRepo.updateSelectedCustomer(null);
        // delete all stored data in session and local storage
        this._appRepo.resetStores().then(() => {
            this._oAuthService.logOut(true);
            if (reLoginViaPortal) {
                window.location.href = environment.portalUrl;
            } else {
                window.location.href = `${environment.loginBaseUrl}/secur/logout.jsp?retUrl=/vforcesite/frontgate%3Fexpid%3DA${environment.appId}L01%26returnUrl%3D${environment.portalUrl}`;
            }
        });
    }

    // starts the login flow of chained request
    public login() {
        this._oAuthService.loadDiscoveryDocumentAndTryLogin({
            disableNonceCheck: true
        }).then((hasReceivedTokens) => {
            // Since salesforce doesn't send an expiration date, we cannot use the automatic silent refresh
            // as a workaround the access token will be refreshed every 10 Minutes. The long therm solution would be
            // to ask the salesforce token introspection endpoint for the expiration date of the access token.
            this._refreshTimer$.pipe(
                takeUntil(this._destroying$),
                switchMap(
                    () => this._oAuthService.refreshToken({ setScope: false })
                        .catch((error) => {
                            throwError(() => new Error("Token is not valid anymore"));
                            this._logout();
                        })),
            ).subscribe();

            // handle code flow, everything else is handled in state machine of constructor
            if (!hasReceivedTokens) {
                this._oAuthService.initCodeFlow()
            }
        });
    }

    /**
     * The function `tryRefresh` attempts to refresh the authentication token, logs out if the token is
     * not valid, and returns the new authentication token if the refresh is successful.
     * @returns `tryRefresh()` returns a Promise that resolves to the new auth token
     */
    public tryRefresh(): Promise<string> {
        return this._oAuthService.refreshToken({ setScope: false })
            .catch((error) => {
                throwError(() => new Error("Token is not valid anymore"));
                this._logout();
            })
            .then((e) => {
                console.log(`%csession restored at ${formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en')}`, 'color: green; font-weight: bold;');
                // return new auth token
                return this._oAuthService.getAccessToken();
            })
    }

    public logout() {
        this._logout();
    }

    public switchCustomer() {
        // resets all data stored in session and local storage
        this._appRepo.updateSelectedCustomer(null);
        this._appRepo.resetStores(['availableCustomers', 'customersConfig']);
        // make sure to not redirect to old url after customer switch
        sessionStorage.removeItem('postLoginURL');
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }
}
