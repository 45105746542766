import { trigger, transition, style, animate } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { NotificationService } from 'src/app/core/app-services';
import { OverviewService } from 'src/app/core/data-backend/data-services';

@Component({
    selector: 'app-restart-popup',
    template: `
        <div 
            *ngIf="isOpen"
            class="restart-popup"
            [@fadeInAnimation]
            #restartPopup
        >
            <div class="restart-title">{{ 'DETAILS_VIEW.RESTARTS.RESTART_STATION' | translate }}</div>
            <div class="restart-body">
                {{ 'DETAILS_VIEW.RESTARTS.RESTART_INFO' | translate: {id: stationId} }}
            </div>
            <button 
                class="restart-button"
                (click)="restartStation(stationId)"
                [disabled]="restarting"
                [class.restarting]="restarting"
            >
                <div class="button-text">
                    <div
                        class="material-icon"
                        [class.spin]="restarting"
                    >sync</div>
                    {{ 'DETAILS_VIEW.RESTARTS.RESTART_NOW' | translate }}
                </div>
            </button>
        </div>
    `,
    styleUrl: './restart-popup.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('fadeInAnimation', [
            transition(':enter', [
                style({ transform: 'translateY(-10px)', opacity: 0 }),
                animate('.25s ease-out', 
                    style({ transform: 'translateY(0px)', opacity: 1 })
                )
            ]),
            transition(':leave', [
                style({ transform: 'translateY(0px)', opacity: 1 }),
                animate('.25s ease-in', 
                    style({ transform: 'translateY(-10px)', opacity: 0 })
                )
            ])
        ])
    ]
})
export class RestartPopupComponent {
    // id of the station to restart
    @Input() stationId: string | null = null;
    // whether or not the restart window should be shown
    public isOpen: boolean | null = null;
    // setter for isOpen
    @Input() set openRestart(isOpen: boolean) {
        if (!this.restarting) {
            this.isOpen = isOpen;
            this.isOpen ? this.openPopup() : this.hidePopup();
        };
    };
    // element ref to restart popup
    @ViewChild('restartPopup') restartPopup!: ElementRef;
    // two way binding openRestart
    @Output() openRestartChange = new EventEmitter<boolean>();
    // whether or not the station is being restarted
    public restarting: boolean | null = false;
    // helper for UI
    private _unlistenEvents: (() => void)[] = [];

    constructor(
        private _overviewService: OverviewService,
        private _notificationService: NotificationService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _renderer: Renderer2,
        private _translate: TranslateService
    ) {}

    restartStation(stationId: string | null) {
        if (!stationId) return;
        this.restarting = true;
        this._overviewService.resetChargingStations({
            body: {stationIds: [stationId]}
        }).pipe(take(1)).subscribe({
            next: (v) => {
                let regex = /success: (\d+), error: (\d+)/;
                let match = v.detail.match(regex);
                if (match) {
                    let [amtSuccess, amtError] = match.slice(1).map(Number);
                    if (amtSuccess > 0) {
                        this._notificationService.showSuccess(
                            this._translate.instant('DETAILS_VIEW.RESTARTS.RESTART_SUCCESS', {id: stationId})
                        );
                    }
                    if (amtError > 0) {
                        this._notificationService.showError(
                            this._translate.instant('DETAILS_VIEW.RESTARTS.RESTART_ERROR', {id: stationId}),
                            this._translate.instant('COMMON.ERROR.ONE')
                        );
                    }
                }
                this.restarting = false;
                this.hidePopup();
            },
            error: (_) => {
                this._notificationService.showError(
                    this._translate.instant('DETAILS_VIEW.RESTARTS.RESTART_ERROR', {id: stationId}),
                    this._translate.instant('COMMON.ERROR.ONE')
                )
                this.restarting = false;
            }
        })
    }

    hidePopup() {
        if (this.restarting) return;
        this.openRestartChange.emit(false);
        this.isOpen = false;
        this._removeListeners();
        this._changeDetectorRef.detectChanges();
    }

    openPopup() {
        this.openRestartChange.emit(true);
        this.isOpen = true;
        this._setListeners();
    }

    private _setListeners() {
        this._unlistenEvents.push(
            this._renderer.listen('document', 'mousedown', (event) => {
                if (
                    !this.restartPopup.nativeElement.contains(event.target) &&
                    !event.target.classList.contains('subnav-restart') &&
                    this.isOpen
                ) this.hidePopup();
            }),
            this._renderer.listen('document', 'keydown.esc', () => this.hidePopup())
        )
    }

    private _removeListeners() {
        this._unlistenEvents.forEach((fn) => fn())
    }
}
