import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, catchError, combineLatest, concatMap, debounceTime, distinctUntilChanged, lastValueFrom, map, Observable, of, retry, switchMap, take, tap, withLatestFrom, forkJoin, mergeMap, share, ReplaySubject, startWith } from 'rxjs';
import { StationColumn, overviewRepository } from 'src/app/core/stores/overview.repository';
import { animate, AUTO_STYLE, style, transition, trigger } from '@angular/animations';
import { appRepository } from 'src/app/core/stores/app.repository';
import { mapRepository } from 'src/app/core/stores/map.repository';
import { GlobalService } from 'src/app/core/app-services/global.service';
import { AdditionalListInformation, selectFilterOption } from './select-filters/select-filters.component';
import { ExportService, NotificationService } from 'src/app/core/app-services';
import { ChargingStation, Connector, SharedFilterSet } from 'src/app/core/data-backend/models';
import { ExportOption, ExportRequestEvent } from '../export/export.component';
import { DetailsOverviewService, FiltersetsService, OverviewService } from 'src/app/core/data-backend/data-services';
import { Filter, stationFiltersRepository } from 'src/app/core/stores/station-filters.repository';
import { ExtendedError } from 'src/app/details/error-history/error-history.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { OverviewCacheService } from 'src/app/core/app-services/overview-cache.service';
import { filterQueryBuilder } from 'src/app/core/helpers/utils.helper';
import { DataLayerService } from 'src/app/core/app-services/data-layer.service';
import { PermissionsService } from 'src/app/core/app-services/permissions.service';


@Component({
    selector: 'app-station-filters',
    template: `
        <evc-collapsible
            [bodyClasses]="[view === 'map' ? 'pb-8' : 'p-0']"
            [class.kpi-collapsible]="view === 'kpi'"
            [parentClasses]="['filter-collapsible']"
            [fullWidth]="view === 'map'"
            [showChevron]="(selectedFilterKeys$ | async)!.length > 0"
            [active]="(selectedFilterKeys$ | async)!.length > 0"
            [collapsed]="(dropdownCollapsed$ | async) ?? true"
            (collapsedChange)="repo.setFiltersCollapsed($event)"
        >
            <ng-container body>
                @if (buttonInfo$ | async; as buttonInfo) {
                    <div 
                        class="body-wrapper"
                        [class.minimize-slot-right]="!buttonInfo.hasFilterSetPermissions"
                    >
                        <div class="filters" #container>
                            <list-filters
                                [filters]="filtersRepo.mappedActiveExceptedFilters$ | async"
                                [additionalInformation]="additionalFilterInformation"
                                [loading]="filtersPolling$ | async"
                                (onNewFilterValue)="handleFilterInStore($event)"
                                (onDeleteFilter)="deleteFilter($event)"
                                (onResetFilter)="resetFilter($event)"
                            >
                            </list-filters>
                        </div>
                        <div class="pr-8 slot-right flex-row justify-content-end align-items-start">
                            @if (buttonInfo.hasFilterSetPermissions) {
                                <button 
                                    class="save-btn"
                                    [class.loading]="buttonInfo.isLoading"
                                    [disabled]="buttonInfo.disabled"
                                    (click)="handleFilterButton(buttonInfo.action, buttonInfo.filterset)"
                                >
                                    @if (buttonInfo.isLoading) {
                                        <app-preloader
                                            size="small"
                                            type="squares"
                                            color="#94A3B8" 
                                        ></app-preloader>
                                    } @else {
                                        {{ buttonInfo.title }}
                                    }
                                </button>
                            }

                            <button 
                                class="icon-btn reset ml-16"
                                [disabled]="buttonsDisabled$ | async"
                                [tooltip]="'FILTERS.RESET_ALL_FILTERS' | translate"
                                [size]="'small'"
                                (click)="filtersRepo.resetAllFilters()"
                            >
                                <span class="material-icon">rotate_right</span>
                            </button>
                            <button 
                                class="icon-btn ml-8"
                                [disabled]="buttonsDisabled$ | async"
                                [tooltip]="'FILTERS.DELETE_ALL_FILTERS' | translate"
                                [size]="'small'"
                                [toSide]="'left'"
                                (click)="deleteAllManualFilters()"
                            >
                                <span class="material-icon">delete_outline</span>
                            </button>
                        </div>
                    </div>
                }
            </ng-container>
        </evc-collapsible>
        <div 
            class="position-absolute filter-collapsible-header"
            [class.map-header]="view === 'map'"
            [class.kpi-header]="view === 'kpi'"
        >
            <div class="container">
                <div class="filter-collapsible-header-padding flex-row align-items-start no-wrap">

                    @if (view != 'kpi') {
                        <div class="text-wrapper flex-column flex-shrink-1">    
                            <div class="text-cont">
                                <span class="title">
                                    @if (view == 'overview') {
                                        {{ 'COMMON.STATION.OTHER' | translate }}
                                    } @else if (view == 'map') {
                                        {{ 'MAP_VIEW.TITLE' | translate }}
                                    }
                                </span>
                                @if (additionalTitle) {
                                    <span 
                                        class="subline pointer-events-all" 
                                        [tooltip]="additionalTitle"
                                        toSide="top"
                                        size="small"
                                        [@textInOut]
                                    >- {{ additionalTitle }}</span>
                                }
                            </div>
                            <div *ngIf="updatedAt">
                                <span [tooltip]="'COMMON.TIME_OF_LAST_UPDATE' | translate" size="small" toSide="bottom" class="timestamp-subtitle pointer-events-all">{{ updatedAt | localizedDate: 'dd.MM.yyyy HH:mm:ss' }}</span>
                            </div>
                        </div>

                        <div class="spacer"></div>

                        <div
                            *evcHasPermissions="'global.stationFilters.quickFilters'" 
                            class="pointer-events-all"
                        >
                            <evc-overall-state-filter/>
                        </div>
                    }
                        
                    <div class="filters-cont flex-row pointer-events-all flex-shrink-0">
                        <select-filters
                            size="small"
                            [filterOptions]="exceptedAvailableFilterKeys$ | async"
                            [filterOptionsPolling]="(filtersRepo.filterVariablesPolling$ | async) ?? false"
                            [filterSets]="(filtersRepo.filterSets$ | async)?.data || null"
                            [filterSetsPolling]="(filtersRepo.filterSets$ | async)?.fetchStatus === 'fetching' || false"
                            [activeFilters]="selectedFilterKeys$ | async"
                            [additionalInformation]="additionalFilterInformation"
                            [collapsible]="true"
                            [view]="view"
                            [alignment]="(view == 'overview' || view == 'map') ? 'right' : 'left'"
                            [style.max-width]="'100%'"
                            (isOpenChange)="onOpenChange(false)"
                            (activeFiltersChange)="handleSelectedFilters($event)"
                            (selectedFilterSet)="filtersRepo.applyFilterSet($event)"
                        >
                        </select-filters>
                    </div>

                    @if (view == 'overview') {
                        <div class="overview-cont flex-row pointer-events-all flex-shrink-0">
                            <div *evcHasPermissions="'dashboard.table.columnSelector'">
                                <div *ngIf="availableTableColumns && availableTableColumns.length > 0">
                                    <app-select-table-columns
                                        [availableTableColumns]="availableTableColumns"
                                        [selectedTableColumns]="selectedTableColumns"
                                    >
                                    </app-select-table-columns>
                                </div>
                            </div>
                            <div *evcHasPermissions="'global.export.stations'">
                                <div *ngIf="exportOptions && exportOptions.length > 0">
                                    <div 
                                        class="overview-export"
                                        (click)="openExport = !openExport"
                                    >
                                        <div class="material-icon overview-exp-icon">download</div>
                                    </div>
                                    <app-export 
                                        [exportOptions]="exportOptions"
                                        [polling]="exportFetching$ | async"
                                        [(isOpen)]="openExport"
                                        (onExportRequest)="handleExport($event)"
                                    ></app-export>
                                </div>
                            </div>
                            <div 
                                class="fullWidth" 
                                [class.active]="repo.fullWidth$ | async"
                                (click)="repo.toggleFullWidth()"
                            ></div>
                        </div>
                    }

                    @if (view == 'map') {
                        <div class="map-cont flex-row pointer-events-all">
                            <ng-container *evcHasPermissions="'operationMap.chart'">
                                <div 
                                    class="graphs"
                                    (click)="mapRepository.toggleGraphsShown()"
                                    [class.active]="mapRepository.showGraphs$ | async"
                                >
                                    <span class="material-icon">bar_chart</span>
                                </div>
                            </ng-container>
                            <ng-container *evcHasPermissions="'operationMap.fullScreen'">
                                <div 
                                    class="fullscreen-button"
                                    (click)="globalService.toggleFullscreen()"
                                    [class.active]="globalService.isFullscreen$ | async"
                                >
                                    <span class="material-icon">fullscreen</span>
                                </div>
                            </ng-container>
                        </div>
                    }
                </div>
            </div>
        </div>
        <filterset-modal
            *ngIf="modalOpen"
            [(open)]="modalOpen"
            [mode]="'create'"
            [canSwitchType]="true"
            [availableFilters]="allAvailableFilterKeys$ | async"
            [preselectedFilters]="filtersRepo.mappedActiveFilters$ | async"
            [baseFilters]="filtersRepo.baseFilters$ | async"
            [currentFilterSets]="(filtersRepo.filterSets$ | async)?.data || []"
        >
        </filterset-modal>
    `,
    styleUrls: ['./station-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('textInOut', [
            transition(':enter', [
                style({ opacity: 0, width: '0px' }),
                animate('.15s ease-in', 
                style({ opacity: 1, width: AUTO_STYLE }))
            ]),
            transition(':leave', [
                style({ opacity: 1, width: AUTO_STYLE }),
                animate('.15s ease-out', 
                style({ opacity: 0, width: '0px' }))
            ])
        ])
    ],
    host: {
        '[class.position-relative]': 'true',
        '[class.d-block]': 'true'
    }
})

export class StationFiltersComponent {
    // timestamp of latest update
    @Input() updatedAt: Date | null = null;
    // pass through to select-table-columns
    @Input('availableTableColumns') availableTableColumns:  StationColumn[] | null = [];
    @Input('selectedTableColumns')  selectedTableColumns:   StationColumn[] | null = [];
    // controls options and layout for each view this comp is embedded in
    @Input() view: 'overview' | 'map' | 'kpi' = 'overview';
    // show additional information on filter variable in list and filter selector
    @Input() additionalFilterInformation: AdditionalListInformation[] = [];
    // appends a grey text to the title
    @Input() additionalTitle: string | null = null
    // keys of all available filters
    allAvailableFilterKeys$: Observable<selectFilterOption[]>
    // keys of available filters with exceptions
    exceptedAvailableFilterKeys$: Observable<selectFilterOption[]>
    // keys of active, selected filters
    selectedFilterKeys$: Observable<string[]>;
    // pass polling state to comps
    pollingInProgress: boolean = false;
    // num of stations with current request
    numOfStations$: Observable<number>;
    fsTransitioning: boolean = false;
    isFullscreen: boolean = false;
    
    // slightly delay collapsed state to not interfere with main thread (keep animation smooth)
    // and prevent layout bugs (overlapping DOM elements)
    public dropdownCollapsed$ = this.repo.filtersCollapsed$.pipe(
        debounceTime(5)
    );
    // controls disabled state of buttons (save as filter set, reset, delete)
    public buttonsDisabled$ = this.filtersRepo.mappedActiveFilters$.pipe(
        map((filters) => !filters || filters.length == 0),
        debounceTime(10),
        share({ connector: () => new ReplaySubject(1) })
    )
    // alters button action and description based on activeFilterSet
    public buttonInfo$: Observable<{
        title: string, // button description
        action: 'subscribe' | 'unsubscribe' | 'save', // button action
        isLoading: boolean,
        disabled: boolean,
        hasFilterSetPermissions: boolean,
        filterset?: SharedFilterSet
    }>;
    private _filterButtonLoading$ = new BehaviorSubject<boolean>(false);
    // whether overall state information is loading
    pollingOverallStateData: boolean = false;
    // state of "create filterset modal"
    modalOpen: boolean = false;
    // combines polling state of all initial options and combinable options
    public filtersPolling$ = combineLatest([
        this.filtersRepo.baseFilterOptionsPolling$,
        this.filtersRepo.combineableFiltersPolling$
    ]).pipe(
        map(([baseFiltersPolling, combineableFiltersPolling]) => baseFiltersPolling || combineableFiltersPolling),
        distinctUntilChanged()
    )
    // export
    public openExport: boolean = false;
    public exportOptions: ExportOption[] = [];
    public exportFetching$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private _exportService: ExportService,
        private _overviewService: OverviewService,
        private _detailsOverviewService: DetailsOverviewService,
        private _filtersetsService: FiltersetsService,
        private _overviewCacheService: OverviewCacheService,
        private _notificationService: NotificationService,
        private _translate: TranslateService,
        private _dataLayerService: DataLayerService,
        private _permService: PermissionsService,
        public repo: overviewRepository,
        public filtersRepo: stationFiltersRepository,
        public mapRepository: mapRepository,
        public appRepository: appRepository,
        public globalService: GlobalService
    ) {
        this.allAvailableFilterKeys$ = this.filtersRepo.filterVariables$.pipe(
            map(({data}) => data.map((filterVar) => ({
                label: filterVar.label,
                value: filterVar.key
            })))
        )

        this.exceptedAvailableFilterKeys$ = combineLatest({
            availableFilterKeys: this.allAvailableFilterKeys$,
            exceptedFilterKeys: this.filtersRepo.filterExceptions$
        }).pipe(
            map(({availableFilterKeys, exceptedFilterKeys}) => {
                if (!availableFilterKeys) return [];
                return availableFilterKeys.filter(filter => 
                    !exceptedFilterKeys.includes(filter.value)
                );
            })
        )

        this.selectedFilterKeys$ = combineLatest({
            activeFilters: this.filtersRepo.activeFilterValues$,
            exceptedFilterKeys: this.filtersRepo.filterExceptions$
        }).pipe(
            map(({activeFilters, exceptedFilterKeys}) => 
                activeFilters
                    .filter((filter) => !exceptedFilterKeys.includes(filter.id))
                    .map((filter) => filter.id)
            )
        )

        this.buttonInfo$ = combineLatest({
            activeFilterSetId: this.filtersRepo.activeFilterSetId$,
            loading: this._filterButtonLoading$,
            allFilterSets: this.filtersRepo.filterSets$,
            buttonsDisabled: this.buttonsDisabled$,
            newLang: this._translate.onLangChange.pipe(startWith(null)),
            hasFilterSetPermissions: this._permService.ofPermission('routes.filterSets')
        }).pipe(
            map(({activeFilterSetId, allFilterSets, loading, buttonsDisabled, hasFilterSetPermissions}) => {
                const filterSet = allFilterSets.data.find((filterSet) => filterSet.filterSetId == activeFilterSetId);
                const isLoading = loading || allFilterSets.isLoading;
                const disabled = buttonsDisabled || isLoading;
                // keeps code shorter
                const t = (s: string) => this._translate.instant(s);

                if (filterSet && filterSet.isShared) {
                    const isSubscribed = (filterSet as SharedFilterSet).isSubscribed;
                    return {
                        title: isSubscribed ? t('FILTERS.UNSUBSCRIBE_FILTERSET') : t('FILTERS.SUBSCRIBE_FILTERSET'),
                        action: isSubscribed ? 'unsubscribe' : 'subscribe',
                        filterset: filterSet as SharedFilterSet,
                        disabled,
                        isLoading,
                        hasFilterSetPermissions
                    }
                } else {
                    return {
                        title: t('FILTERS.SAVE_FILTERSET'),
                        action: 'save',
                        disabled,
                        isLoading,
                        hasFilterSetPermissions
                    };
                }
            })
        )

        // get total amt of stations
        this.numOfStations$ = this.repo.stationLocations$.pipe(
            map((res) => res.data.stationLocations.length)
        )        

        // keep export option error report updated
        combineLatest({
            rows: this.repo.allbulkActionRows$,
            numOfStations: this.numOfStations$,
            newLang: this._translate.onLangChange.pipe(startWith(null))
        }).pipe(
            takeUntilDestroyed(),
            tap(({rows, numOfStations}) => {
                // only enable error report if any station has a lastError
                // as lastError is based on the last 14 days, which would be our export range
                const anyRowHasLastError = rows.some((station) => station.connectors.some((con) => con.lastError !== undefined && con.lastError !== null));
                this.exportOptions = [
                    {
                        label: this._translate.instant('DASHBOARD.EXPORT.SELECTED_COLUMNS'),
                        value: 'selected-columns',
                        disabled: numOfStations === 0
                    },
                    {
                        label: this._translate.instant('DASHBOARD.EXPORT.ALL_COLUMNS'),
                        value: 'all-columns',
                        disabled: numOfStations === 0
                    },
                    {
                        label: this._translate.instant('DASHBOARD.EXPORT.ERROR_REPORT'),
                        value: 'error-report',
                        disabled: !anyRowHasLastError,
                        tooltip: this._translate.instant('DASHBOARD.EXPORT.ERROR_REPORT_TOOLTIP')
                    }
                ];
            })
        ).subscribe();
    }

    // handles different action types for filterSet button
    public handleFilterButton(action: 'subscribe' | 'unsubscribe' | 'save', filterSet?: SharedFilterSet) {
        if (action == 'save') {
            this.modalOpen = true
        } else if (filterSet) {
            this._filterButtonLoading$.next(true);
            this._filtersetsService.subscribeFilterSet({
                filtersetid: filterSet.filterSetId,
                body: {subscribe: !filterSet.isSubscribed}
              }).pipe(
                take(1),
                tap(_ => {
                    const text = !filterSet.isSubscribed ? 'FILTER_SETS_VIEW.INFO.SUBSCRIBED' : 'FILTER_SETS_VIEW.INFO.UNSUBSCRIBED';
                    this._notificationService.showLocalizedSuccess(text);
                    this._overviewCacheService.updateFilterSetsCache();
                    this._overviewCacheService.updateAlertsCache();
                    this._filterButtonLoading$.next(false);
                }),
                catchError(_ => {
                    const text = !filterSet.isSubscribed ? 'FILTER_SETS_VIEW.INFO.ERROR_SUBSCRIBED' : 'FILTER_SETS_VIEW.INFO.ERROR_UNSUBSCRIBED'; 
                    this._notificationService.showLocalizedError(text);
                    this._filterButtonLoading$.next(false);
                    return of([]);
                })
            ).subscribe();
        }
    }


    // adds / removes filters selected in select-multiple
    handleSelectedFilters(selectedKeys: string[]) {
        this.filtersRepo.activeFilterValues$.pipe(
            take(1),
            withLatestFrom(this.filtersRepo.filterExceptions$),
            tap(([activeFilters, filterExceptions]) => {
                // filters currently in store
                // - do not update previously selected filters
                // - filterExceptions are added to the selectedKeys, as they are set by the app itself
                // - remove set filters not included in current selection from store
                // - add new filters from selection

                // disconnect from active filter set
                this.filtersRepo.updateActiveFilterSetId(null);

                selectedKeys = [...selectedKeys, ...filterExceptions]

                const activeFiltersIds = activeFilters.map(filter => filter.id);
                // get activeFilters that are not set in current selection
                const filtersToDelete = activeFiltersIds.filter((activeFilterId) => {
                    return selectedKeys.indexOf(activeFilterId) === -1
                });
                // get filters from current selection that are not yet set in store
                const filtersToAdd = selectedKeys.filter((selectedKey) => {
                    return activeFiltersIds.indexOf(selectedKey) === -1
                })

                const currView = this.view === 'overview' ? 'dashboard' : this.view;

                filtersToDelete.forEach((filter) => {
                    this.filtersRepo.deleteFilters(filter);
                    this._dataLayerService.logFilterSelection('filter-remove', currView, filter);
                })
                filtersToAdd.forEach((filterId) => {
                    this.filtersRepo.addFilter(filterId, null);
                    this._dataLayerService.logFilterSelection('filter-select', currView, filterId);
                })
                
                // open collapsible if new filter(s) added
                if (filtersToAdd.length > 0) this.repo.setFiltersCollapsed(false)
            })
        ).subscribe()
    }

    onOpenChange(collapsed: boolean) {
        // only open collapsible if at least one filter is selected
        this.selectedFilterKeys$.pipe(take(1)).subscribe(keys => {
            if (collapsed || (!collapsed && keys.length > 0)) {
                this.repo.setFiltersCollapsed(collapsed);
            }
        });
    }

    // adds / updates filters in store
    // accessed by updating filter values in collapsible body
    handleFilterInStore(event: [Filter['id'], any]) {
        const [filterId, value] = event;
        
        // disconnect from last filter set when values are modified
        this.filtersRepo.updateActiveFilterSetId(null);

        if (this.filtersRepo.hasFilter(filterId)) {
            this.filtersRepo.updateFilterValue(filterId, value)
        } else {
            this.filtersRepo.addFilter(filterId, value)
        }
    }

    deleteFilter(filterId: Filter['id']) {
        this.filtersRepo.updateActiveFilterSetId(null);
        this.filtersRepo.deleteFilters(filterId);
        const currView = this.view === 'overview' ? 'dashboard' : this.view;
        this._dataLayerService.logFilterSelection('filter-remove', currView, filterId);
    }

    resetFilter(filter: Filter) {
        this.filtersRepo.updateActiveFilterSetId(null);
        this.filtersRepo.updateFilterValue(filter.id, this._getEmptyFilterValue(filter));
    }

    private _getEmptyFilterValue(filter: Filter): any {
        switch (filter.type) {
            case 'select-multiple': case 'range':
                return []
            case 'select-single-radio':
                return ''
            case 'date-range': case 'date-time-range':
                return undefined
        }
    }

    public handleExport(event: ExportRequestEvent) {
        this.exportFetching$.next(true)

        if (event.value === 'selected-columns' || event.value === 'all-columns') {
            // get all available stations of current req config
            let reqCounter$ = new BehaviorSubject<number>(1),
            stationsList: ChargingStation[] = [];

            const results$: Observable<any[]> = combineLatest([
                this.repo.searchQuery$,
                this.filtersRepo.activeFilterValues$,
                this.repo.showOnlyHidden$,
                this.repo.sortBy$,
                this.repo.sortDirection$,
                this.repo.stationsMeta$
            ]).pipe(
                take(1),
                concatMap(([searchQuery, activeFilters, showOnlyHidden, sortBy, sortDirection, meta]) => {
                    let totalAmtofStations  = meta.maxPage * meta.perPage, // approx amt of all available stations
                        amtOfRequests       = Math.ceil(totalAmtofStations / 10000), // break down into multiple reqs if too many stations
                        numOfStations       = Math.ceil(totalAmtofStations / amtOfRequests);
                    return reqCounter$.pipe(
                        switchMap(counter => {
                            let request = this._requestBuilder({
                                searchQuery: searchQuery,
                                activePageNum: counter,
                                perPage: numOfStations,
                                activeFilters: activeFilters,
                                showOnlyHidden: showOnlyHidden,
                                sortBy: sortBy,
                                sortDirection: sortDirection
                            });

                            return this._overviewService.getChargingStations(request).pipe(
                                retry({count: 1, delay: 5000}),
                                catchError(() => of({stations: []})),
                                map(res => {
                                    let currReqCounter = reqCounter$.getValue();
                                    // trigger new req unless last call or this call already only had a fraction of the perPage
                                    if (currReqCounter < amtOfRequests && (res.stations || []).length == numOfStations) {
                                        reqCounter$.next(currReqCounter + 1)
                                    }
                                    if (res.stations) {
                                        stationsList = stationsList.concat(res.stations)
                                    }
                                    return stationsList
                                })
                            )
                        })
                    )
                }),
                take(1),
                map((stations) => {
                    // currently selected columns
                    let columns: StationColumn[] | null = this.selectedTableColumns;
                    if (columns == null) return [];
                    // export either full set of data or filter first
                    let dataToExport: any[] = [];
                    // always keep these columns
                    const fixedColumns: (keyof ChargingStation | keyof Connector)[] = ['stationId', 'connectorId'];

                    for (let i = 0; i < stations.length; i++) {
                        const station       = stations[i];
                        const connectors    = station.connectors;
                        delete (station as any)['connectors']

                        dataToExport.push(...connectors.map((connector: any) => Object.assign(connector, station)))
                    }

                    if (event.value === 'selected-columns') {
                        // get unique keys of columns
                        const selectedKeys = [...new Set(columns.flatMap((col) => [...(col.keyInArray ?? []), ...(col.keyInConnector ?? [])]))]
                        // prepend fixed columns
                        fixedColumns.forEach((col) => {
                            if (!selectedKeys.includes(col)) selectedKeys.unshift(col)
                        })

                        dataToExport = this._exportService.filterHashArray(dataToExport, selectedKeys)
                    } else {
                        // sort: stationId, connectorId, rest alphabetically
                        dataToExport = dataToExport.map((row) => {
                            return Object.keys(row).sort().sort((keyA: any, keyB: any) => {
                                // get pos in ordered array
                                let posA = fixedColumns.indexOf(keyA),
                                    posB = fixedColumns.indexOf(keyB);
                            
                                if (posA === -1) return 1
                                if (posB === -1 || posB > posA) return -1
                                return 0
                            }).reduce((obj: any, key: string) => {
                                obj[key] = row[key];
                                return obj
                            }, {})
                        })
                    }
                    this.exportFetching$.next(false)
                    return dataToExport
                })
            )
            this._exportService.exportCSVPromise(lastValueFrom(results$))
        }

        if (event.value === 'error-report') {
            const results$: Observable<any[]> = this.repo.allbulkActionRowIDs$.pipe(
                take(1),
                switchMap((stationIds) => {
                    const now = new Date();
                    // create requests for every station id
                    return forkJoin(
                        stationIds.map((stationId) =>
                            this._detailsOverviewService.getErrorsOfChargingStation({
                                stationId: stationId,
                                date: now.toISOString(),
                                interval: 14
                            }).pipe(
                                catchError((_) => of([])),
                                mergeMap((errors) =>
                                    this._detailsOverviewService.getChargingStation({stationId}).pipe(
                                        map((station) => {
                                            return {
                                                station: station,
                                                errors: errors
                                            };
                                        })
                                    )
                                )
                            )
                        )
                    ).pipe(
                        map((data) => {
                            // prepare info in export file
                            const errors = data.map((d) => {
                                return d.errors.map((err) => {
                                    // fill extented information from station
                                    const connector = d.station.connectors.find((con) => con.connectorId == err.connectorId);
                                    const error: ExtendedError = {
                                        ...err,
                                        stationId: d.station.stationId,
                                        socketType: connector?.socketType,
                                        currentType: connector?.currentType
                                    };
                                    // sort keys - stationId, connectorId then alphabetically
                                    const keyOrder = ["stationId", "connectorId"];
                                    const sortedKeys = Object.keys(error).sort((a, b) => {
                                        const indexA = keyOrder.indexOf(a);
                                        const indexB = keyOrder.indexOf(b);                                        
                                        if (indexA !== -1 && indexB !== -1) return indexA - indexB;
                                        else if (indexA !== -1) return -1;
                                        else if (indexB !== -1) return 1;
                                        else return a.localeCompare(b);
                                    });
                                    return sortedKeys.reduce((sortedError: any, key) => {
                                        sortedError[key] = error[key as keyof ExtendedError];
                                        return sortedError as ExtendedError;
                                    }, {});
                                });
                            });
                            this.exportFetching$.next(false);
                            return errors.flat();
                        })
                    )
                })
            )
            this._exportService.exportCSVPromise(lastValueFrom(results$), 'error_report');
        }
    }

    public deleteAllManualFilters() {
        this.filtersRepo.activeFilterValues$.pipe(
            take(1),
            withLatestFrom(this.filtersRepo.filterExceptions$),
            tap(([activeFilters, filterExceptions]) => {
                // only delete active filters that were manually set by the user
                // except filterExceptions, as they are managed by the app
                const filtersToDelete = activeFilters
                    .filter((filter) => !filterExceptions.includes(filter.id))
                    .map((filter) => filter.id);

                const currView = this.view === 'overview' ? 'dashboard' : this.view;
                filtersToDelete.forEach((filterId) => this._dataLayerService.logFilterSelection('filter-remove', currView, filterId));

                this.filtersRepo.deleteFilters(filtersToDelete);
            })
        ).subscribe()
    }

    private _requestBuilder(config: {
        searchQuery?: string,
        activePageNum?: number,
        perPage?: number,
        activeFilters?: any[], 
        sortBy?: string, 
        sortDirection?: string, 
        showOnlyHidden?: boolean
    }): any {
        let request: { [key: string]: any } = {};

        // build request
        request["perPage"]          = config.perPage        ? config.perPage : 100;
        request["search"]           = config.searchQuery    ? config.searchQuery : null;
        request["pageNumber"]       = config.activePageNum  ? config.activePageNum : 1;
        request["sortBy"]           = config.sortBy         ? config.sortBy : null;
        request["sortOrder"]        = config.sortDirection  ? config.sortDirection  : null;
        request["showOnlyHidden"]   = config.showOnlyHidden

        if (config.activeFilters) {
            request["filter"] = filterQueryBuilder(config.activeFilters);
        }
        return request
    }
}
