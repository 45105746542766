
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Renderer2, ElementRef, ViewChild, ViewChildren, AfterViewInit, OnDestroy, NgZone, HostListener, input, effect, signal } from '@angular/core';
import { ChargingStation, Connector, StationLocations } from 'src/app/core/data-backend/models';
import { EnumeratedState, StateColors, StateHelperService } from 'src/app/core/helpers/state-helper.service';
import * as mapboxgl from 'mapbox-gl';
import { Router } from '@angular/router';
import { ContextMenuDirective } from 'src/app/core/directives/contextMenu.directive';
import { BehaviorSubject, Observable, Subject, asyncScheduler, combineLatest, debounceTime, delay, filter, fromEvent, map, startWith, takeUntil, tap, throttleTime, withLatestFrom } from 'rxjs';
import { PermissionsService } from 'src/app/core/app-services/permissions.service';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop'
import { environment } from "src/environments/environment";
import { TranslateService } from '@ngx-translate/core';

export interface StationsMapSettings {
    padding: {
        top: number,
        right: number,
        bottom: number,
        left: number
    },
    hideIconsHeight: number | null,
    logoPosition: 'bottom-left' | 'top-left'
}

export type StationsMapBounds = {lng: [min: number, max: number], lat: [min: number, max: number]};

@Component({
    selector: 'app-stations-map',
    template: `
        <div 
            class="mapbox-wrapper"
            (resized)="resizeMap()"
            [class.fullscreen]="isFullscreen"
        >
            <div 
                class="mapbox-map"
                [class.loading]="isLoading"
                #mapContainer
            ></div>
            <app-preloader
                *ngIf="isLoading"
                class="preloader"
            > 
            </app-preloader>
        </div>
        <ng-container *evcHasPermissions="'routes.stationDetails'">
            <ng-container *ngIf="interactionMode !== 'emission'" [contextMenu]="['Open in new tab']" (onContextOptionSelect)="openInNewTab($event)"></ng-container>
        </ng-container>

        <div
            *ngIf="interactionMode === 'popup-analytics'"
            class="analytics-popup-wrapper"
            #analyticsPopupWrapper
        >
            <analytics-popup
                [stationId]="newStationHovered$ | async"
            ></analytics-popup>
        </div>
    `,
    styleUrls: ['./stations-map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StationsMapComponent implements AfterViewInit, OnDestroy {
    private readonly _destroying$ = new Subject<void>();
    @Input() set stationLocations(data: {stationLocations: StationLocations[] | ChargingStation[] | null, isAutoUpdate: boolean} | null) {
        if (data === null || data.stationLocations == null) return
        // transform Array of type ChargingStation to StationLocations
        if ((data.stationLocations[0] as ChargingStation)?.['connectors'] !== undefined) {
            // attribute "connectors" only exists for type ChargingStation, thus we need to build object of type StationLocations
            this._stationLocations = data.stationLocations.map((station: any) => {
                const connectors = station.connectors;
                return {
                    customEvseId:       connectors.map((x: Connector) => x.customEvseId),
                    lastOverallState:   connectors.map((x: Connector) => x.lastOverallState),
                    latitude:           station.latitude,
                    longitude:          station.longitude,
                    stationId:          station.stationId,
                } as StationLocations;
            });
        } else {   
            this._stationLocations = data.stationLocations as StationLocations[];
        }
        // handle marker types based on data size
        this._dynamicMarkers();
        this.updateMapData(!data.isAutoUpdate, this.selectedStations() ?? []);
    };
    // stores ref to last popup ("popup-simple")
    private _latestPopup: mapboxgl.Popup | undefined;
    // controls interaction mode, either emits the clicked stationId or shows a popup ("simple" on click, "analytics" on hover, persists on click)
    @Input() interactionMode: 'emission' | 'popup-simple' | 'popup-analytics' = 'emission';
    // controls marker styling 
    // dot = not aggregated, better performance
    // icon = stations at exact same locations are aggregated, HTML markers are used, will be 
    //        overwritten by dot mode if over configured iconMarkerThreshold
    private _markerMode: 'dot' | 'icon' = 'dot';
    // if true - no stations displayed nor stationsInView emitted
    private _isEmptyState: boolean = false;
    // how long the zoom transition takes in ms
    private _zoomDuration: number = 900;
    // stores initial markerMode to better handle dynamic markers
    private _preferredMarkerMode: 'dot' | 'icon' = 'dot';
    @Input() set markerMode(mode: 'dot' | 'icon') {
        this._preferredMarkerMode = mode;
        this._markerMode = mode;
        this._dynamicMarkers()
    };
    // controls if stations in view should be calculated
    emitVisibleStations = input<boolean>(false);
    // controls wether to emit bounds on map move
    emitBounds = input<boolean>(false);
    // set initial bounds
    @Input() initalBounds: StationsMapBounds | null = null;
    // optionally highlight current station
    private _highlightedStation: ChargingStation | undefined;
    @Input() set highlightedStation (station: ChargingStation | undefined) {
        if (!station || !station.latitude || !station.longitude) return;
        this._highlightedStation = station;
    }
    // select multiple stations for "selection" -> stations not in selection are greyed out
    selectedStations = input<Array<ChargingStation['stationId']> | null>(null);
    // zooms into featured station
    @Input() set featuredStation(station: ChargingStation | StationLocations | null) {
        if (!this._stationMap || !station || !station.latitude || !station.longitude) return
        this._stationMap.easeTo({
            center: [station.longitude, station.latitude],
            zoom: 11,
            duration: this._zoomDuration
        })
        if (this.interactionMode !== 'emission') {
            // toggle popup on HTML markers
            if (this._markerMode == 'icon') {
               this._openHTMLPopup(station.stationId)
            } else {
                // toggle popup on canvas marker
                this._openSimplePopup(station.stationId, this._zoomDuration)
            }
        }
    }
    // general component settings that can be overwritten
    @Input() mapSettings: StationsMapSettings | undefined;
    // define zoom level at which map should switch to satellite view, undefined = always stays on roads view
    @Input() showSatelliteAt: number | undefined;
    // threshold for HTML markers
    @Input() iconMarkerThreshold: number = 200;
    // controls if map fitBounds should be animated
    @Input() fitAnimation: 'long' | 'short' | 'none' = 'long';
    // updates classes and calls resize of map
    public isFullscreen: boolean = false;
    @Input() set fullscreen(value: boolean | null) {
        this.isFullscreen = value === null ? false : value;

        if (this.isFullscreen && this._stationMap) {
            setTimeout(() => this._stationMap?.resize(), 200);
        }
    }
    @Input() isLoading: boolean | null = false;
    // controls delayed init, or possibly pausing of updates while hidden
    private _isVisible: boolean = true;
    @Input() set isVisible(value: boolean | null) {
        if (value == null) return
        this._isVisible = value
        this._initMap();
        this._fitBounds();
    }
    // sets currently hovered stationId, or null if mouseout
    private _stationHovered$ = new BehaviorSubject<ChargingStation['stationId'] | null>(null);
    // Observable based off _stationHovered$, will not emit twice and will not emit if last station was clicked
    public newStationHovered$: Observable<ChargingStation['stationId'] | null>;
    // emits clicked station id if mode == emission
    // if combined with markerMode 'icon' (which will group overlaying stations), only the first stationId of group will be emitted
    private _stationClicked$ = new Subject<ChargingStation['stationId'] | null>();
    @Output() stationClicked = new EventEmitter<ChargingStation['stationId']>();
    // emits changes in currently visible stations if emitVisibleStations = true
    @Output() stationsInView = new EventEmitter<ChargingStation['stationId'][]>();
    // subject used to throttle emissions from map for better performance in larger dataSets
    private _stationsInView$ = new Subject<ChargingStation['stationId'][]>();
    // emits change in longitude and latitude of map bounds if map is moved and emitBounds = true
    @Output() boundsChange = new EventEmitter<StationsMapBounds>();
    // subject used to throttle emissions from map for better performance in larger dataSets
    private _boundsChange$ = new Subject<StationsMapBounds | null>();
    @ViewChild('mapContainer') mapContainer: ElementRef | undefined;
    @ViewChildren(ContextMenuDirective) directives: any;
    @ViewChild('analyticsPopupWrapper') analyticsPopupWrapper: ElementRef | undefined;
    // pos of analytics popup
    public analyticsPopupPosition$ = new BehaviorSubject<{left: number, top: number}>({left: 100, top: 100});

    private _stationLocations: StationLocations[]   = [];
    private _stateHelper: StateHelperService        = new StateHelperService();
    private _stateColors: StateColors               = this._stateHelper.getStateColors();
    private _lastOverallStates: EnumeratedState[]   = ['No Data', 'Ok', 'To Be Monitored', 'Potential Failure', 'Failure'];
    private _overallStateColors: string[]           = [
        this._stateColors.noData, 
        this._stateColors.ok, 
        this._stateColors.toBeMonitored, 
        this._stateColors.potentialFailure, 
        this._stateColors.failure
    ];
    private _stationMap: mapboxgl.Map | undefined;
    private _iconMarkers: mapboxgl.Marker[] = [];
    private _mapSettings: StationsMapSettings = {
        padding: {
            top: 50,
            right: 50,
            bottom: 50,
            left: 50
        },
        hideIconsHeight: null,
        logoPosition: 'bottom-left'
    };
    private _lastClickedId: ChargingStation['stationId'] | undefined;
    private _mapStyles = {
        roads: {
            source: 'mapbox://styles/schaublipsiori/cljcw78i8000201qs0o0ieen1',
            active: true
        },
        satellite: {
            source: 'mapbox://styles/schaublipsiori/cljwoqcgv023q01pk7r63eatg',
            active: false
        }
    };

    constructor(
        private _renderer: Renderer2,
        private _router: Router,
        private _ngZone: NgZone,
        private _permService: PermissionsService,
        private _translate: TranslateService
    ) {
        // update map language
        const newLang = toSignal(this._translate.onLangChange);
        effect(() => {
            const lang = newLang()?.lang ?? 'en';
            if (!this._stationMap) return;
            // apply language if style is already finished loading
            if (this._stationMap.isStyleLoaded()) {
                this._setLanguage(lang);
            } else {
                // else wait for style.load event to apply language
                this._stationMap.once('style.load', () => {
                    this._setLanguage(lang);
                })
            }
        })

        combineLatest({
            stationsInView: this._stationsInView$.pipe(startWith([])),
            boundsChange: this._boundsChange$.pipe(
                startWith(null),
                debounceTime(250)
            )
        }).pipe(
            takeUntilDestroyed(),
            debounceTime(100),
            tap(({stationsInView, boundsChange}) => {
                // check for emission flags, doing this in the pipe allows us to 
                // change the value in run time and keep functionality, as pipe is always subscribed to,
                // resulting in a more versatile component
                if (this.emitVisibleStations()) this.stationsInView.emit(this._isEmptyState ? [] : stationsInView);
                if (this.emitBounds() && boundsChange !== null) this.boundsChange.emit(boundsChange);
            })
        ).subscribe()

        // helper to stop updating popup position once station was clicked
        const keepAnalyticsPopup$ = new BehaviorSubject<boolean>(false);

        // use subject to emit clicked stationId
        this._stationClicked$.pipe(
            takeUntilDestroyed(),
            withLatestFrom(this._stationHovered$),
            tap(([clickedId, hoveredId]) => {
                if (this.interactionMode == 'emission' && clickedId) this.stationClicked.emit(clickedId)

                if (this.interactionMode == 'popup-analytics' && clickedId && hoveredId) {
                    keepAnalyticsPopup$.next(clickedId === hoveredId)
                }
            })
        ).subscribe()

        // update analytics popup position while last station was not clicked
        this.analyticsPopupPosition$.pipe(
            takeUntilDestroyed(),
            throttleTime(80),
            withLatestFrom(keepAnalyticsPopup$),
            filter(([pos, keepAnalyticsPopup]) => !keepAnalyticsPopup),
            map(([pos]) => pos),
            tap((pos) => {
                if (this.interactionMode == 'popup-analytics' && this.analyticsPopupWrapper) {
                    this._renderer.setStyle(this.analyticsPopupWrapper.nativeElement, 'left', pos.left + 10 + 'px')
                    this._renderer.setStyle(this.analyticsPopupWrapper.nativeElement, 'top', pos.top + 'px')
                
                    if (pos.left + 10 + 480 > window.innerWidth) {
                        this._renderer.setStyle(this.analyticsPopupWrapper.nativeElement, 'transform', 'translateX(calc(-100% - 20px))')
                    } else {
                        this._renderer.setStyle(this.analyticsPopupWrapper.nativeElement, 'transform', 'translateX(0%)')
                    }
                }
            })
        ).subscribe()

        // visibility of analytics popup, throttling and filtering out emissions while last station was clicked
        this.newStationHovered$ = this._stationHovered$.pipe(
            debounceTime(20),
            withLatestFrom(keepAnalyticsPopup$),
            filter(([stationId, keepAnalyticsPopup]) => !keepAnalyticsPopup),
            map(([stationId]) => stationId),
            tap((stationId) => {
                if (this.interactionMode == 'popup-analytics' && this.analyticsPopupWrapper) {
                    if (stationId == null) {
                        this._renderer.setStyle(this.analyticsPopupWrapper.nativeElement, 'opacity', 0)
                        this._renderer.setStyle(this.analyticsPopupWrapper.nativeElement, 'pointer-events', 'none')
                    } else {
                        this._renderer.setStyle(this.analyticsPopupWrapper.nativeElement, 'opacity', 1)
                        this._renderer.setStyle(this.analyticsPopupWrapper.nativeElement, 'pointer-events', 'all')
                    }
                }
            })
        )

        // sets single event listener to reset popup on new interaction with map
        keepAnalyticsPopup$.pipe(
            takeUntilDestroyed(),
            delay(50),
            tap((keep) => {
                if (keep == true && this.interactionMode == 'popup-analytics' && this._stationMap) {
                    const eventListener = () => {
                        // reenable events for analytics popup
                        keepAnalyticsPopup$.next(false)
                        // remove current hovered station
                        this._stationHovered$.next(null)
                        // unlisten to set events
                        this._stationMap?.off('mousedown', eventListener)
                        this._stationMap?.off('wheel', eventListener)
                    }
                    // listen to click and zoom event on map
                    this._stationMap.on('mousedown', eventListener);
                    this._stationMap.on('wheel', eventListener)
                }
            })
        ).subscribe()

        effect(() => {
            this.updateMapData(false, this.selectedStations() ?? [])
        })
    }

    private _openSimplePopup(stationId: StationLocations['stationId'], duration: number = 0) {
        // remove last popup
        if (this._latestPopup) this._latestPopup.remove()
        // store id, needed for "open in new tab" option
        this._lastClickedId = stationId;
        const station = this._stationLocations.find((station) => station.stationId === stationId)
        if (!station || !station.latitude || !station.longitude) return;
        // create content DOM node
        const content = this._renderer.createElement('div');
        content.classList.add('station-popup-content');
        const isLinked = this._permService.hasPermission('routes.stationDetails');
        // add link to station
        const stationIdEl = this._renderer.createElement(isLinked ? 'a' : 'p');
        stationIdEl.textContent = stationId;
        this._renderer.appendChild(content, stationIdEl)
        // add evseIds if available
        if (station && station.customEvseId && station.customEvseId.length > 0) {
            const evseIds = this._renderer.createElement('p');
            station.customEvseId.forEach((evseId, index) => {
                if (evseId !== null) {
                    evseIds.textContent += `${evseId} ${index + 1 !== station.customEvseId.length ? '\r\n' : ''}`
                }
            });
            this._renderer.appendChild(content, evseIds)
        }
        // only link to station details if user has permissions to view
        if (isLinked) {
            // let renderer listen to click on content
            this._renderer.listen(stationIdEl, 'click', (e) => {
                // navigate to station
                this._ngZone.run(() => 
                    this._router.navigate(['details', stationId])
                )
            });
            // listen to right click, pass event to directive
            this._renderer.listen(stationIdEl, 'contextmenu', (event) => {
                this.directives?.first?.onContextMenu(event)
            })
        }

        // create and add popup to map
        this._latestPopup = new mapboxgl.Popup({
                closeButton: false,
                focusAfterOpen: true,
                className: 'station-popup'
            })
            .setLngLat([station.longitude, station.latitude])
            .setDOMContent(content);

        // add popup after zoom transition if markers are shown
        setTimeout(() => {
            if (!this._isEmptyState && this._latestPopup) {
                this._latestPopup.addTo(this._stationMap!)
            }
        }, duration)
    }

    private _openHTMLPopup(stationId: StationLocations['stationId']) {
        const station = this._stationLocations.find((station) => station.stationId === stationId);
        if (!station || !station.latitude || !station.longitude) return;
        // close all popups
        this._iconMarkers.filter((marker) => marker.getPopup().isOpen())
            .forEach((marker) => {
                marker.togglePopup()
            });
        // find marker at featured station location
        const stationMarkerDistances = this._iconMarkers.map((marker) => {
            // @ts-ignore
            const stationLngLat = new mapboxgl.LngLat(station.longitude, station.latitude);
            return marker.getLngLat().distanceTo(stationLngLat)
        })
        const closestMarker = this._iconMarkers[stationMarkerDistances.indexOf(Math.min(...stationMarkerDistances))];
        // if available, toggle its popup
        closestMarker.togglePopup()
    }

    private _initMap() {
        if (this._stationMap || !this.mapContainer) return;
        // update mapSettings
        if (this.mapSettings) this._mapSettings = this.mapSettings

        // keep map outside app zone
        this._ngZone.runOutsideAngular(() => {
            // helper for eventListener
            let firstStyleLoad = true,
                mapBounds: mapboxgl.LngLatBounds | undefined;

            if (this.initalBounds) {
                mapBounds = new mapboxgl.LngLatBounds(
                    [this.initalBounds.lng[0], this.initalBounds.lat[1]],
                    [this.initalBounds.lng[1], this.initalBounds.lat[0]]
                )
            }

            // init map
            this._stationMap = new mapboxgl.Map({
                container: this.mapContainer?.nativeElement,
                style: this._mapStyles.roads.source,
                attributionControl: false,
                accessToken: environment.mapboxToken,
                bounds: mapBounds,
                logoPosition: this._mapSettings.logoPosition
            })
            // add eventListener on charging stations canvas
            .on('click', 'charging-stations', (event) => {
                const clickedId = event.features?.[0]?.properties?.['stationId'];
                if (clickedId == undefined) return

                this._ngZone.run(() => 
                    this._stationClicked$.next(clickedId)
                )
                
                if (this.interactionMode == 'popup-simple') this._openSimplePopup(clickedId)
            }).on('style.load', () => {
                // once style finished loading, (re-)add source and layer for charging stations
                this._stationMap!.addSource('charging-stations', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: []
                    }
                });

                // set dot layer if markerMode dot is selected
                // markerMode 'icon' will set HTML Markers after updateMapData() was called
                this._patchDotLayer();

                // set initial language
                this._setLanguage(this._translate.currentLang);

                // call function to fill previously added source
                // only reset zoom on very first style load ("fit map after init") and if no init bounds are set!
                // any style.load events after the initial style will be caused by a map-base style change 
                // (e.g., when switching to sattelite and back)
                this.updateMapData(!this.initalBounds && firstStyleLoad, this.selectedStations() ?? []);
                firstStyleLoad = false
            })

            if (this.showSatelliteAt !== undefined) {
                const setMapStyle = (key: keyof typeof this._mapStyles) => {
                    // set requested style
                    this._stationMap!.setStyle(this._mapStyles[key].source)
                    this._mapStyles[key].active = true
                    // get other key, flag style as inactive
                    const keys = Object.keys(this._mapStyles);
                    const keyIndex = keys.indexOf(key);
                    keys.splice(keyIndex, 1);
                    const otherKey = keys[0]
                    this._mapStyles[otherKey as keyof typeof this._mapStyles].active = false
                }

                // listen to zoom - change style
                this._stationMap.on('zoom', (e) => {
                    if (!this._stationMap) return

                    // set style to satellite if zoomed in close enough
                    if (this._stationMap.getZoom() > this.showSatelliteAt!) {
                        if (this._mapStyles.satellite.active) return
                        setMapStyle('satellite')
                    } else if (this._mapStyles.satellite.active) {
                        setMapStyle('roads')
                    }
                })
            }

            // add hover listener
            if (this.interactionMode == 'popup-analytics') {
                this._stationMap.on('mousemove', 'charging-stations', (event) => {
                    const hoveredId = event.features?.[0]?.properties?.['stationId'];

                    this._ngZone.run(() => {
                        this._stationHovered$.next(hoveredId)
                        this.analyticsPopupPosition$.next({
                            left: event.point.x,
                            top: event.point.y
                        })
                    })
                })

                this._stationMap.on('mouseout', 'charging-stations', () => {
                    this._ngZone.run(() => {
                        this._stationHovered$.next(null)
                    })
                })
            }

            if (this._mapSettings.hideIconsHeight !== null) {
                // store original value to compare against for the case this setting is changed after initialization (which is not currently supported)
                const originalHideIconsHeight = this._mapSettings.hideIconsHeight;
                fromEvent(this._stationMap, 'zoom').pipe(
                    // use takeUntil as takeUntilDestroyed can't be used outside of an injection context
                    takeUntil(this._destroying$),
                    throttleTime(100, asyncScheduler, {leading: true, trailing: true}),
                    tap((event) => {
                        const zoom = event.target.getZoom();

                        if (!this._isEmptyState && zoom <= originalHideIconsHeight) {
                            // if zoomed out beyond threshold
                            this._isEmptyState = true;
                        } else if (this._isEmptyState && zoom > originalHideIconsHeight) {
                            // if zoomed within threshold
                            this._isEmptyState = false;
                        }

                        // remove popup if zoomed out
                        if (this._isEmptyState && this._latestPopup) this._latestPopup.remove();
                    })
                ).subscribe();
            }

            fromEvent(this._stationMap, 'move').pipe(
                // use takeUntil as takeUntilDestroyed can't be used outside of an injection context
                takeUntil(this._destroying$),
                throttleTime(180, asyncScheduler, {leading: true, trailing: true}),
                tap((event) => {
                    const bounds = this._stationMap!.getBounds();

                    if (this.emitBounds()) {
                        this._ngZone.run(() =>
                            // add current bounds to subject
                            this._boundsChange$.next({
                                lng: [bounds.getWest(), bounds.getEast()],
                                lat: [bounds.getSouth(), bounds.getNorth()]
                            })
                        )
                    }

                    if (this.emitVisibleStations()) {
                        const stationsInBounds = this._stationLocations.reduce((acc, sl) => {
                            if (
                                sl.longitude &&
                                sl.latitude &&
                                bounds.contains([sl.longitude, sl.latitude])
                            ) {
                                acc.push(sl.stationId);
                            }
                            return acc;
                        }, [] as string[]);

                        // run emission in zone for angulars change detection
                        this._ngZone.run(() => 
                            this._stationsInView$.next(stationsInBounds)
                        )
                    }
                })
            ).subscribe()
        })
    }

    ngAfterViewInit(): void {
        if (this._isVisible) {
            this._initMap()
        }
    }

    public openInNewTab(event: string) {
        if (event == 'Open in new tab' && !this._lastClickedId) return
        const url = this._router.serializeUrl(
            this._router.createUrlTree([`/details/${this._lastClickedId}`])
        );

        window.open(url, '_blank')
    }

    public updateMapData(
        resetZoom: boolean = true,
        selectedStations: Array<ChargingStation['stationId']> = []
    ) {
        if (!this._stationMap) return

        const source = this._stationMap.getSource('charging-stations') as mapboxgl.GeoJSONSource;
        if (!source) return

        // all states in order "No Data" will be rendered first, all other points overlap, etc. Failure always on top
        let stateStations: {[name: string]: StationLocations[]} = {
            'No Data': [],
            'Ok': [],
            'To Be Monitored': [],
            'Potential Failure': [],
            'Failure': []
        };

        // separate stationLocations by lowest overall state to render it in order
        this._stationLocations.forEach(station => {
            let [ worstState ] = this._stateHelper.getLowestEnumeratedStateInArray(station.lastOverallState as EnumeratedState[]);
            stateStations[worstState || 'No Data'].push(station)
        })

        // get primary color to color border of highlighted station
        const primary = this._stateHelper.getVar('primary.base');

        // returns true if no selectedStations are set or provided id is part of selection
        const stationIsSelected = (stationId: string): boolean => {
            return selectedStations.length === 0 || selectedStations.includes(stationId)
        }

        // create features set sorted by lowest overall state
        const features: any[] = Object.keys(stateStations).flatMap((state) => {
            const locationsOfState = stateStations[state];
            // get color of state
            const stateIndex = this._lastOverallStates.indexOf(state as EnumeratedState);
            const stateColor = this._overallStateColors[stateIndex] ?? this._stateColors.noData;
            return locationsOfState
            .filter((location) => location.longitude && location.latitude)
            .map((location) => {
                const isSelected = stationIsSelected(location.stationId);
                const isHighlighted = this._highlightedStation?.stationId === location.stationId;
                const stationColor = isSelected ? stateColor : '#475569';
                const circleOpacity = isSelected ? 1 : 0.8;
                const circleRadius = isSelected ? 7.5 : 6.5;
                const borderColor = isHighlighted ? primary : 'white';
                const borderWidth = isHighlighted ? 3 : isSelected ? 1.5 : .5;
                
                let feature: Record<string, any> = {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [location.longitude, location.latitude]
                    },
                    properties: {
                        stationId: location.stationId,
                        stationState: state ?? 'No Data',
                        stationColor,
                        borderColor,
                        circleRadius,
                        borderWidth,
                        circleOpacity
                    }
                }

                // only add "isSelected" property if selectedStations or highlighted station are set
                // this will be used to reorder stations in the canvas layer to be on top
                if (selectedStations.length > 0 || isHighlighted) {
                    feature['properties']['isSelected'] = stationIsSelected(location.stationId) || isHighlighted
                }

                return feature
            })
        })

        // if selectedStations are set, sort features by isSelected property
        // this will render selected stations on top of unselected stations
        if (selectedStations.length > 0 || this._highlightedStation !== null) {
            features.sort((a, b) => {
                const isSelectedA = a.properties.isSelected || false;
                const isSelectedB = b.properties.isSelected || false;
                
                if (isSelectedA && !isSelectedB) {
                    return 1;
                } else if (!isSelectedA && isSelectedB) {
                    return -1;
                } else {
                    return 0;
                }
            });
        }

        source.setData({
            type: 'FeatureCollection',
            features: features
        })

        if (this._markerMode == 'icon') {
            this._setHTMLMarker()
        } else {
            // toggle Popup of highlightedStation
            if (this._highlightedStation) this._openSimplePopup(this._highlightedStation.stationId)
        }

        if (resetZoom) this._fitBounds()
    }

    // collects all station locations and fits map boundaries around them
    private _fitBounds() {
        if (!this._stationMap) return;
    
        const parsedToFixed = (val: number): number => parseFloat((val).toFixed(6))

        let mapBoundaries = new mapboxgl.LngLatBounds();

        if (this._highlightedStation && this._highlightedStation.latitude && this._highlightedStation.longitude) {
            // create boundaries around highlightedStation with 5km radius
            mapBoundaries = new mapboxgl.LngLat(this._highlightedStation.longitude, this._highlightedStation.latitude).toBounds(5000);
        } else {
            // get highest and lowest lat/lng values of all stationLocations
            const bounds = this._stationLocations.reduce((acc, {longitude, latitude}) => {
                if (longitude) {
                    acc.minLng = Math.min(acc.minLng, longitude);
                    acc.maxLng = Math.max(acc.maxLng, longitude);
                }
                if (latitude) {
                    acc.minLat = Math.min(acc.minLat, latitude);
                    acc.maxLat = Math.max(acc.maxLat, latitude);
                }
                return acc;
            }, {
                // use infinity to make sure at least one of the provided lat/lng values will override
                minLng: Infinity,
                maxLng: -Infinity,
                minLat: Infinity,
                maxLat: -Infinity
            });

            // return if any value is Inifinty || -Infinity (should only be the case if no locations are provided)
            const boundValues = Object.values(bounds);
            if (boundValues.includes(Infinity) || boundValues.includes(-Infinity)) return

            // create boundaring points of highest and lowest long-/latitudes (sw and ne) with padding
            mapBoundaries = new mapboxgl.LngLatBounds([
                [parsedToFixed(bounds.minLng - this._mapSettings.padding.left), parsedToFixed(bounds.minLat - this._mapSettings.padding.bottom)],
                [parsedToFixed(bounds.maxLng + this._mapSettings.padding.right), parsedToFixed(bounds.maxLat + this._mapSettings.padding.top)]
            ]);
        }

        // map fitbounds options
        let animationOptions: mapboxgl.FitBoundsOptions = {};
        
        // set animation
        if (this.fitAnimation == 'short') {
            const center = mapBoundaries.getCenter();
            // get distance in m from center to north west corner of current bounds
            const approxDistance = center.distanceTo(mapBoundaries.getNorthWest());
            // returns new boundaries based on given radius in meter
            const expandedBounds = center.toBounds(approxDistance * 2.5)
            // set bounds without animation, then update with "real bounds"
            this._stationMap.fitBounds(expandedBounds, {animate: false})
        } else if (this.fitAnimation == 'none') {
            // disable animation
            animationOptions.animate = false;
        }

        // set fitbounds with current config
        this._stationMap!.fitBounds(mapBoundaries, animationOptions)        
    }

    // adds dot layer if not set yet
    // helps component to handle dynamic marker modes (e.g. HTML below certain threshold, dots above that)
    private _patchDotLayer() {
        if (!this._stationMap) return

        // check if layer is present
        const layer = this._stationMap.getLayer('charging-stations');
        // remove if HTML markers are selected
        if (this._markerMode == 'icon' && layer) this._stationMap.removeLayer('charging-stations');
        // return if layer is present
        if (layer || this._markerMode !== 'dot') return;

        const add = () => {
            // set blurred dots layer
            const blurredLayer: mapboxgl.AnyLayer = {
                id: 'charging-stations-blurred',
                type: 'circle',
                source: 'charging-stations',
                filter: [
                    'all',
                    ['==', ['get', 'stationState'], 'No Data'], // only add shadow to no-data
                    ['>', ['zoom'], 8] // hide shadows at a certain zoom level, we only need the shadows close-up
                ],
                paint: {
                    'circle-radius': ['get', 'circleRadius'], // keep same radius as real dot above
                    'circle-color': 'rgba(58, 58, 58, .3)', // same shadow color for all dots
                    'circle-stroke-width': 6, // larger stroke width to increase dot-area
                    'circle-stroke-color': 'rgba(58, 58, 58, .3)', // same color as the circle body
                    'circle-opacity': ['get', 'circleOpacity'], // keep opacity of dot above, else this might lead to random shadows where dots are hidden with opacity: 0
                    'circle-blur': .8
                },
            };

            // set dot layer
            let stationsLayer: mapboxgl.AnyLayer = {
                id: 'charging-stations',
                type: 'circle',
                source: 'charging-stations',
                paint: {
                    'circle-color': ['get', 'stationColor'],
                    'circle-radius': ['get', 'circleRadius'],
                    'circle-stroke-width': ['get', 'borderWidth'],
                    'circle-stroke-color': ['get', 'borderColor'],
                    'circle-opacity': ['get', 'circleOpacity']
                }
            };

            // add filter expressions if hideIconHeight is defined
            if (this._mapSettings.hideIconsHeight !== null) {
                const filterExpression = ['>=', ['zoom'], this._mapSettings.hideIconsHeight];
                blurredLayer.filter!.push(filterExpression);
                stationsLayer['filter'] = ['all', filterExpression];
            }

            // add layers
            this._stationMap!.addLayer(blurredLayer);
            this._stationMap!.addLayer(stationsLayer);
        }

        // coordinate patch to only add layer if style is fully loaded
        // else wait for next style.loaded event to apply
        if (this._stationMap.isStyleLoaded()) {
            add()
        } else {
            this._stationMap.once('style.load', () => add())
        }
    }
    
    // handle marker modes for performance optimization
    private _dynamicMarkers() {
        // disable markerMode "icon" for larger dataSets (each station would add a html element to the dom), default to dot
        if (this._stationLocations.length > this.iconMarkerThreshold) {
            this._markerMode = 'dot';
        } else {
            // return to inital configuration
            this._markerMode = this._preferredMarkerMode
        };

        this._patchDotLayer();
    }

    // manually sets HTML markers based on map's geojson source
    private _setHTMLMarker() {
        // class to identify markers when setting / deleting
        const stationsMarkerClass = 'stations-marker';

        // delete all previously set elements from DOM
        if (this.mapContainer) {
            this.mapContainer.nativeElement.querySelectorAll(`.${stationsMarkerClass}`).forEach((el: HTMLElement) => el.remove())
        }

        if (!this._stationLocations) return;

        // group stations by locations
        let chargerGroups = this._stationLocations.reduce((store: any, item) => {
            let group = `${item.latitude?.toString()},${item.longitude?.toString()}`;
            // set 'store' for this instance of group to the outer scope (if not empty) or initialize it
            // add this item to its group within 'store'
            if (item.latitude && item.longitude) (store[group] ||= []).push(item);
            return store;
        }, {});

        Object.keys(chargerGroups).forEach((group, index) => {
            let stations: StationLocations[] = chargerGroups[group];
            // highlight marker if featured stationId is provided and in this group
            const containsCurrentStation = this._highlightedStation !== undefined && stations.map(x => x.stationId).indexOf(this._highlightedStation.stationId) > -1;

            // get worst overallState of all stations at location
            const statesAtLocation: StationLocations['lastOverallState'] = stations.flatMap((station) => station.lastOverallState);
            const [ groupState, groupColor ] = this._stateHelper.getLowestEnumeratedStateInArray(statesAtLocation as EnumeratedState[]);

            // create content of marker
            const content = this._renderer.createElement('div');
            content.classList.add(stationsMarkerClass)
            const marker = this._renderer.createElement('div');
            marker.classList.add('marker-background');
            if (containsCurrentStation) marker.classList.add('highlight')
            const symbol = this._renderer.createElement('span');
            symbol.classList.add('marker-symbol');
            symbol.style.backgroundColor = groupColor;

            // add symbol to marker parent (white circle in background)
            this._renderer.appendChild(marker, symbol);
            this._renderer.appendChild(content, marker)

            if (stations.length > 1) {
                // add aggregation marker if more than one station is in this group
                const aggregationCounter = this._renderer.createElement('div');
                aggregationCounter.classList.add('aggregation-info');
                aggregationCounter.textContent = stations.length;
                this._renderer.appendChild(content, aggregationCounter)
            }

            const mapboxMarker = new mapboxgl.Marker(content)
                .setLngLat([
                    stations[0].longitude as number,
                    stations[0].latitude as number
                ])
                .addTo(this._stationMap!);

            if (this.interactionMode !== 'emission') {
                // prepare popup, add to marker
                // this causes mapbox to handle click events on markers
                const content = this._renderer.createElement('div');
                content.classList.add('station-popup-content');
                // only show each stations evseId if less than 5 stations at this location
                const showEvseIds = stations.length < 5;
                // add information of each station in this group
                stations.forEach((station) => {
                    const [ stationState, stationColor ] = this._stateHelper.getLowestEnumeratedStateInArray(station.lastOverallState as EnumeratedState[]);
                    // main info wrapper
                    const stationContent = this._renderer.createElement('div');
                    // row for stationId and overall state dot
                    const stationRow = this._renderer.createElement('div');
                    stationRow.classList.add('flex-row', 'align-items-center', 'justify-content-start')
                    // create state dot, set corresponding color
                    const stateDot = this._renderer.createElement('span');
                    stateDot.classList.add('state-dot')
                    stateDot.style.backgroundColor = stationColor;
                    // wrap stationId in paragraph
                    const stationId = this._renderer.createElement('p');
                    stationId.classList.add('text-bold', 'pl-8')
                    stationId.textContent = station.stationId;
                    // build "station header"
                    this._renderer.appendChild(stationRow, stateDot)
                    this._renderer.appendChild(stationRow, stationId)
                    this._renderer.appendChild(stationContent, stationRow)

                    // append evseIds if possible
                    if (showEvseIds) {
                        const evseIds = this._renderer.createElement('p');
                        evseIds.classList.add('evse-ids')
                        station.customEvseId.forEach((evseId, index) => {
                            if (evseId !== null) {
                                evseIds.textContent += `${evseId} ${index + 1 !== station.customEvseId.length ? '\r\n' : ''}`
                            }
                        });
                        this._renderer.appendChild(stationContent, evseIds)                  
                    }

                    // add station info to main popup content
                    this._renderer.appendChild(content, stationContent)
                })

                this._latestPopup = new mapboxgl.Popup({
                    closeButton: false,
                    focusAfterOpen: true,
                    className: 'station-popup',
                    offset: 19
                }).setDOMContent(content)

                mapboxMarker.setPopup(this._latestPopup)

                // store marker ref for later modifications
                this._iconMarkers.push(mapboxMarker);
            } else {
                // manually set listener to content inside marker to emit clicked id
                this._renderer.listen(content, 'click', () => {
                    this._stationClicked$.next(stations[0].stationId)
                })
            }
        })

        // initially open popup of highlighted station
        if (this.interactionMode !== 'emission' && this._highlightedStation) this._openHTMLPopup(this._highlightedStation.stationId)
    }

    private _setLanguage(lang: string) {
        if (!this._stationMap) return;
        this._stationMap.setLayoutProperty('country-label', 'text-field', [
            'get',
            `name_${lang}`
        ]);
    }

    @HostListener('window:resize')
    resizeMap() {
        this._stationMap?.resize()
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
        this._stationMap?.remove();
    }
}
